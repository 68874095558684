import { Suspense, useEffect, useLayoutEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import AuthGuard from "@/auth-guard"

import Footer from "@/components/footer"
import Loading from "@/components/loading"
import useMyInfo from "@/hooks/use-my-info"
import useStoreInfo from "@/hooks/use-store-info"
import useToast from "@/hooks/use-toast"
import AnalyticsProvider from "@/lib/analytics/AnalyticsProvider"
import Header from "./components/header"
import Sidebar from "./components/sidebar"

const DashboardLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const myInfo = useMyInfo()
  const storeInfo = useStoreInfo()

  const toast = useToast()

  useEffect(() => {
    if (!myInfo?.id || myInfo.store) return

    if (!myInfo.store) {
      toast.warning("원활한 서비스 이용을 위해 스토어를 등록해주세요.", {
        id: "store-warning",
      })
      navigate("/sign-up", { replace: true })
      return
    }

    toast.error("잘못된 접근 입니다.")
    navigate("/", { replace: true })
  }, [myInfo])

  useLayoutEffect(() => {
    if (!location.pathname.includes("management")) {
      if (storeInfo?.status === "DRAFT") {
        toast.warning("사업자 정보 등록이 필요한 메뉴입니다.")
        navigate("/dashboard/management/biz-info", { replace: true })
      }
    }
  }, [location.pathname])

  return (
    <AnalyticsProvider>
      <AuthGuard>
        <Header />
        <main className="grid min-h-[calc(100vh-64px-270px)] grid-cols-[264px_1fr] md:min-h-[calc(100vh-64px-122px)]">
          <Sidebar />
          <div className="h-full border-t border-t-gray-300">
            <Suspense
              fallback={
                <div className="flex size-full items-center justify-center">
                  <Loading />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </main>
        <Footer />
      </AuthGuard>
    </AnalyticsProvider>
  )
}

export default DashboardLayout
