import { Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import { ArrowUpIcon } from "@/assets/icons"

import Footer from "@/components/footer"
import Loading from "@/components/loading"
import AnalyticsProvider from "@/lib/analytics/AnalyticsProvider"
import Header from "./components/header"

const PublicLayout = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollToTop(true)
    } else {
      setShowScrollToTop(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <AnalyticsProvider>
      <Header />
      <main className="flex min-h-[calc(100vh-60px-270px)] flex-col md:min-h-[calc(100vh-80px-122px)]">
        <Suspense
          fallback={
            <div className="flex flex-1 items-center justify-center">
              <Loading />
            </div>
          }
        >
          <Outlet />
        </Suspense>

        {showScrollToTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-5 right-5 z-50 flex size-10 items-center justify-center rounded-full bg-white shadow-md"
          >
            <ArrowUpIcon className="size-5" />
          </button>
        )}
      </main>

      <Footer />
    </AnalyticsProvider>
  )
}

export default PublicLayout
