import { METHOD } from "@runners/lib/http"
import { PaymentMethod, SettlementShippingType, SettlementStatus } from "@/pages/dashboard/settlement/constants"
import api from "."
import { commonPolicyCreateDto } from "./common-policy-api"
import { ShippingPolicyCreateDto } from "./shipping-policy-api"

export type ListQueryParams = {
  page_size?: number
  page?: number
  search?: string
  ordering?: string
}

export type DjangoListResponse<T> = {
  code: number
  message: string
  count: number
  next: string | null
  previous: string | null
  data: T[]
}

export type DjangoResponse<T> = {
  code: number
  message: string
  data: T
}

export enum StoreStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
}

export type PostStorePayload = {
  contact_email: string
  phone: string
  business_number: string
  business_type: string
  tax_type: string
  title: string
  owner_name: string
  industry_type: string
  sub_industry_type: string
  address: string
  detail_address: string
  zip_code: string
  status: StoreStatus
}

type StoreParams = {
  storeId: number
}

type PostStoreValidatePayload = {
  business_number: string
}

type StoreUpdateDto = {
  business_number?: string // 사업자 등록번호
  owner_name?: string // 대표자 이름
  title?: string // 타이틀
  address?: string // 사업장 주소
  detail_address?: string // 사업장 상세 주소
  zip_code?: string // 우편번호
  business_registration_image_url?: string // 사업자 등록증 이미지 URL
  telecommunication_retail_number?: string // 통신 판매업 신고 번호
  telecommunication_retail_image_url?: string // 통신 판매 신고증 이미지 URL
  bank_code?: string // 은행 코드
  account_owner_name?: string // 예금주 이름
  account_number?: string // 정산계좌
  bank_statement_copy_image_url?: string // 통장 사본 이미지
  business_type?: string // 사업자 형태
  industry_type?: string // 업태
  sub_industry_type?: string // 종목
  tax_type?:
    | "GENERAL"
    | "SIMPLIFIED"
    | "SPECIAL"
    | "EXEMPT"
    | "NON_PROFIT_CORPORATION"
    | "ORGANIZATION_WITH_UNIQUE_CODE"
    | "TAX_INVOICE_ISSUING" // 과세 타입
  phone?: string // 전화
  contact_email?: string // 연락 이메일
  status?: StoreStatus // 상태
  store_link?: string // 스토어 링크
}

type PatchStorePayload = {
  storeId: number
  payload: StoreUpdateDto
}

export type StoreSellerListParams = {
  storeId: number
  params?: ListQueryParams
}

export type StoreCommonPolicyListParams = {
  storeId: number
  params?: ListQueryParams
}

export type StoreShippingPolicyListParams = {
  storeId: number
  params?: ListQueryParams
}

type SellerDto = {
  id: number
  user: {
    email: string
    phone: string
    username: string
  }
  role: SellerRole
}

type PostStoreSellerPayload = {
  storeId: number
  payload: { user: number }
}

export enum SellerRole {
  대표관리자 = "대표관리자",
  담담자 = "담당자",
  관리자 = "관리자",
  배송담당자 = "배송담당자",
  정산담당자 = "정산담당자",
}

type PatchSellerPayload = {
  sellerId: number
  payload: { role: SellerRole }
}

type DeleteSellerPayload = { sellerId: number }

type PostStoreApplyPayload = {
  store_title: string
  store_link: string
  has_store_link: boolean
  business_type: string
  applier_name: string
  applier_email: string
  content?: string
}

type PostStoreProductInitialPayload = {
  store_link?: string
  images_data: string[]
}

type PostStoreProductsCommonPolicyPayload = {
  product_ids: number[]
  common_product_policy: number
}

type PostStoreProductsShippingPolicyPayload = {
  product_ids: number[]
  shipping_product_policy: number
  can_combine_shipping: boolean
  combine_shipping_product_policy?: number
}

type PostOrdersExcelPayload = {
  order_ids: number[]
  password: string
  password_confirm: string
}

export type CombineShippingPolicyPayload = {
  title: string
  is_used: boolean
  combine_fee_type: "MIN" | "MAX"
  is_extra_fee: boolean
  jeju_extra_fee: number
  etc_extra_fee: number
}

export type CombineShippingPolicyDto = {
  id: number
  policy_number: string
  title: string
  combine_fee_type: "MIN" | "MAX"
  created: string
}

export type CombineShippingPolicyDetailDto = {
  is_used: boolean
  is_extra_fee: boolean
  jeju_extra_fee: string | null
  etc_extra_fee: string | null
} & CombineShippingPolicyDto

export interface PostProductsExcelPayload {
  file: File
  product_subcategory: number
}

export interface StoreProductUploadDto {
  product_number: string
  status: string
  product_title: string
  product_status: string
  category_title: string
  product_discount_price: string
  product_stock_count: string
}

export interface StoreProductUploadListDto {
  id: number
  total_count: number
  success_count: number
  fail_count: number
  failed_excel_url: string | null
  created: string
  product_uploads: StoreProductUploadDto[]
}

export interface StoreImagesParams {
  type?: "THUMBNAIL" | "ADDITION" | "DETAIL"
  page?: number
  page_size?: number
}

export interface StoreImagesDto {
  id: number
  url: string
  type: "THUMBNAIL" | "ADDITION" | "DETAIL"
}

export interface PostStoreImagesPayload {
  type: "THUMBNAIL" | "ADDITION" | "DETAIL"
  files: FormData
}

export interface PostSToreImagesCopyPayload {
  type: "THUMBNAIL" | "ADDITION" | "DETAIL"
  image_ids: number[]
}

/**
 * 여기부터 정산
 */
export interface SettlementGroupDto {
  id: number
  title: string
  count: number
  settlement_cycle: string
  total_sales_price: number
  total_supply_price: number
  tax_amount: number
  pg_fee: number
  total_settlement_amount: number
  status: SettlementStatus
  expected_date: string | null
}

export interface SettlementGroupParams {
  search?: string
  status?: SettlementStatus | SettlementStatus[]
  start_date?: string
  end_date?: string
}

export interface SettlementDto {
  id: number
  payment_method: PaymentMethod
  order_number: string
  product_title: string
  product_option_title: string | null
  orderer_name: string
  ordered_at: string
  shipping_type: SettlementShippingType
  sales_price: number
  supply_price: number
}

export const StoreQueryKeys = {
  all: () => ["store"],
  store: (storeId: number) => [...StoreQueryKeys.all(), storeId],
  sellerList: ({ storeId, params }: StoreSellerListParams) => [...StoreQueryKeys.store(storeId), "sellerList", params],
  commonPolicyList: ({ storeId, params }: StoreCommonPolicyListParams) => [
    ...StoreQueryKeys.store(storeId),
    "commonPolicyList",
    { ...params },
  ],
  shippingPolicyList: ({ storeId, params }: StoreShippingPolicyListParams) => [
    ...StoreQueryKeys.store(storeId),
    "shippingPolicyList",
    { ...params },
  ],

  combineShippingPolicyList: ({ storeId, params }: StoreShippingPolicyListParams) => [
    ...StoreQueryKeys.store(storeId),
    "combineShippingPolicyList",
    { ...params },
  ],

  combineShippingPolicy: ({
    storeId,
    combineShippingPolicyId,
  }: {
    storeId: number
    combineShippingPolicyId?: number
  }) => [...StoreQueryKeys.store(storeId), "combineShippingPolicy", combineShippingPolicyId],

  bulkProduct: {
    all: ({ storeId }: { storeId: number }) => [...StoreQueryKeys.store(storeId), "bulkProduct"],
    lastHistory: ({ storeId }: { storeId: number }) => [...StoreQueryKeys.bulkProduct.all({ storeId }), "lastHistory"],
  },

  storeImage: {
    all: ({ storeId }: { storeId: number }) => [...StoreQueryKeys.store(storeId), "storeImage"],
    params: ({ storeId, params }: { storeId: number; params: StoreImagesParams }) => [
      ...StoreQueryKeys.storeImage.all({ storeId }),
      { ...params },
    ],
  },
}

const storeApi = {
  getStore: async ({ storeId }: StoreParams) => {
    const { data } = await api.get(`/business/store/${storeId}`)
    return data
  },

  postStore: async (payload: PostStorePayload) => {
    const { data } = await api.post("/business/store", payload)
    return data
  },

  patchStore: async ({ storeId, payload }: PatchStorePayload) => {
    const { data } = await api.patch(`/business/store/${storeId}`, payload)
    return data
  },

  postStoreValidate: async (payload: PostStoreValidatePayload) => {
    const { data } = await api.post("/business/store/validate", payload)
    return data
  },

  getStoreSellerList: async ({ storeId, params }: StoreSellerListParams) => {
    const { data } = await api.get<DjangoListResponse<SellerDto>>(`/business/store/${storeId}/sellers`, { params })
    return data
  },

  postStoreSeller: async ({ storeId, payload }: PostStoreSellerPayload) => {
    const { data } = await api.post(`/business/store/${storeId}/seller`, payload)
    return data
  },

  patchSeller: async ({ sellerId, payload }: PatchSellerPayload) => {
    const { data } = await api.patch(`/business/seller/${sellerId}`, payload)
    return data
  },

  deleteSeller: async ({ sellerId }: DeleteSellerPayload) => {
    const { data } = await api.delete(`/business/seller/${sellerId}`)
    return data
  },

  getStoreCommonPolicyList: async ({ storeId, params }: StoreCommonPolicyListParams) => {
    const { data } = await api.get<DjangoListResponse<{ id: number; title: string }>>(
      `/business/store/${storeId}/common-policies`,
      { params },
    )
    return data
  },

  postStoreCommonPolicy: async ({ storeId, payload }: { storeId: number; payload: commonPolicyCreateDto }) => {
    const { data } = await api.post(`/business/store/${storeId}/common-policy`, payload)
    return data
  },

  /**
   * 주문 엑셀 생성
   */
  postStoreOrdersExcel: async ({ storeId, payload }: { storeId: number; payload: PostOrdersExcelPayload }) => {
    const { data } = await api(`/business/store/${storeId}/orders/excel`, {
      method: "POST",
      data: payload,
    })
    return data
  },

  getStoreShippingPolicyList: async ({ storeId, params }: StoreShippingPolicyListParams) => {
    const { data } = await api.get<DjangoListResponse<any>>(`/business/store/${storeId}/shipping-policies`, { params })
    return data
  },

  postStoreShippingPolicy: async ({ storeId, payload }: { storeId: number; payload: ShippingPolicyCreateDto }) => {
    const { data } = await api.post(`/business/store/${storeId}/shipping-policy`, payload)
    return data
  },

  /**
   * 묶음 배송 정책 리스트 조회
   */
  getCombineShippingPolicyList: async ({ storeId, params }: { storeId: number; params?: ListQueryParams }) => {
    const { data } = await api<DjangoListResponse<CombineShippingPolicyDto>>(
      `/business/store/${storeId}/combine-shipping-policies`,
      { params },
    )
    return data
  },

  /**
   * 묶음 상품 배송 정책 생성
   */
  postStoreCombineShippingPolicy: async ({
    storeId,
    payload,
  }: {
    storeId: number
    payload: Partial<CombineShippingPolicyPayload>
  }) => {
    const { data } = await api.post(`/business/store/${storeId}/combine-shipping-policy`, payload)
    return data
  },

  /**
   * 묶음 배송 정책 삭제
   */
  deleteCombineShippingPolicy: async ({ combineShippingPolicyId }: { combineShippingPolicyId: number }) => {
    const { data } = await api(`/business/combine-shipping-policy/${combineShippingPolicyId}`, {
      method: METHOD.DELETE,
    })
    return data
  },

  /**
   * 묶음 배송 정책 객체 조회
   */
  getCombineShippingPolicy: async ({ combineShippingPolicyId }: { combineShippingPolicyId?: number }) => {
    const { data } = await api<DjangoResponse<CombineShippingPolicyDetailDto>>(
      `/business/combine-shipping-policy/${combineShippingPolicyId}`,
    )
    return data
  },

  /**
   * 묶음 배송 정책 수정
   */
  patchCombineShippingPolicy: async ({
    combineShippingPolicyId,
    payload,
  }: {
    combineShippingPolicyId: number
    payload: Partial<CombineShippingPolicyPayload>
  }) => {
    const { data } = await api.patch(`/business/combine-shipping-policy/${combineShippingPolicyId}`, payload)
    return data
  },

  postStoreApply: async (payload: PostStoreApplyPayload) => {
    const { data } = await api.post("/business/store/apply", payload)
    return data
  },

  /**
   * 묶음 배송 정책 타이틀 유효성 검사
   */
  postCombineShippingPolicyTitleCheck: async ({ title, store }: { title: string; store: number }) => {
    const { data } = await api(`/business/combine-shipping-policy/title/check`, {
      method: METHOD.POST,
      data: { title, store },
    })
    return data
  },

  /**
   * 묶음 배송 정책 복제
   */
  postCombineShippingPolicyCopy: async ({ combineShippingPolicyId }: { combineShippingPolicyId: number }) => {
    const { data } = await api(`/business/combine-shipping-policy/${combineShippingPolicyId}/copy`, {
      method: METHOD.POST,
    })
    return data
  },

  /**
   * 상품 최초 생성
   */
  postStoreProductInitial: async ({
    storeId,
    payload,
  }: {
    storeId: number
    payload: PostStoreProductInitialPayload
  }) => {
    const { data } = await api(`/business/store/${storeId}/product/initial`, {
      method: "POST",
      data: payload,
    })
    return data
  },

  /**
   * 상품 공통 정보 일괄 적용
   */
  postStoreProductsCommonPolicy: async ({
    storeId,
    payload,
  }: {
    storeId: number
    payload: PostStoreProductsCommonPolicyPayload
  }) => {
    const { data } = await api(`/business/store/${storeId}/products/common-policy`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 상품 배송 정보 일괄 적용
   */
  postStoreProductsShippingPolicy: async ({
    storeId,
    payload,
  }: {
    storeId: number
    payload: PostStoreProductsShippingPolicyPayload
  }) => {
    const { data } = await api(`/business/store/${storeId}/products/shipping-policy`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 상품 엑셀 일괄 등록
   */
  postStoreProductsExcel: async ({ storeId, payload }: { storeId: number; payload: FormData }) => {
    const { data } = await api(`/business/store/${storeId}/products/excel`, {
      method: METHOD.POST,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return data
  },

  /**
   * 스토어 상품일괄등록 객체 조회
   */
  getStoreProductUploads: async ({ storeId }: { storeId: number }) => {
    const { data } = await api<DjangoResponse<StoreProductUploadListDto>>(`/business/store/${storeId}/product-uploads`)
    return data
  },

  /**
   * 스토어 이미지 리스트 조회
   */
  getStoreImages: async ({ storeId, params }: { storeId: number; params: StoreImagesParams }) => {
    const { data } = await api<DjangoListResponse<StoreImagesDto>>(`/business/store/${storeId}/images`, { params })
    return data
  },

  /**
   * 다중 스토어 이미지 생성
   */
  postStoreImages: async ({ storeId, payload }: { storeId: number; payload: FormData }) => {
    const { data } = await api(`/business/store/${storeId}/images`, {
      method: METHOD.POST,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return data
  },

  /**
   * 스토어 이미지 복제
   */
  postStoreImagesCopy: async ({ storeId, payload }: { storeId: number; payload: PostSToreImagesCopyPayload }) => {
    const { data } = await api(`/business/store/${storeId}/images/copy`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 스토어 이미지 선택 삭제
   */
  deleteStoreImages: async ({ storeId, image_ids }: { storeId: number; image_ids: number[] }) => {
    const { data } = await api(`/business/store/${storeId}/images`, {
      method: METHOD.DELETE,
      params: {
        image_ids,
      },
    })
    return data
  },

  /** ------------------------------------------------------------------------------
   * 
   * 여기부터 정산
   * 
   ------------------------------------------------------------------------------ */
  /**
   * 정산 그룹 리스트 조회
   */
  getStoreSettlementGroups: async ({ storeId, params }: { storeId: number; params?: SettlementGroupParams }) => {
    const { data } = await api<DjangoListResponse<SettlementGroupDto>>(`/business/store/${storeId}/settlement-groups`, {
      params,
    })
    return data
  },

  /**
   * 정산 그룹 객체 조회
   */
  getStoreSettlementGroup: async ({ settlementGroupId }: { settlementGroupId: number }) => {
    const { data } = await api<DjangoListResponse<SettlementDto>>(`/business/settlement-group/${settlementGroupId}`)
    return data
  },

  /**
   * 정산 엑셀 생성
   */
  postSettlementGroupExcel: async ({ settlementGroupId }: { settlementGroupId: number }) => {
    const { data } = await api(`/business/settlement-group/${settlementGroupId}/excel`, {
      method: METHOD.POST,
    })
    return data
  },
}

export default storeApi
