import React, { useState } from "react"
import { Button, Input } from "@runners/ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import productApi from "@/apis/productApi"
import storeApi, { StoreQueryKeys } from "@/apis/storeApi"
import { DownloadIcon, WarningOutlineIcon } from "@/assets/icons"
import withModalLayout from "@/hoc/with-modal-layout"
import useModalStack from "@/hooks/use-modal"
import useMyInfo from "@/hooks/use-my-info"
import useToast from "@/hooks/use-toast"
import BulkProductsGuide from "./bulk-products-guide"
import CategorySelectModal from "../../product/components/category-select-modal"
import { ProductFormFields } from "../../product/detail"

type Category = ProductFormFields["category"]

interface ExcelBulkProductsModalProps {}

const ExcelBulkProductsModal = withModalLayout<ExcelBulkProductsModalProps>(props => {
  const { close } = props

  const myInfo = useMyInfo()

  const queryClient = useQueryClient()

  const [excel, setExcel] = useState<File | undefined>(undefined)

  /**
   * GET 메서드지만 mutate로 관리합니다
   */
  const { mutate } = useMutation({
    // queryKey: ProductQueryKeys.subCategoryExcel({ categoryId: subCategory }),
    mutationFn: productApi.getProductSubcategoryExcel,
    onSuccess: response => {
      window.open(response.data.download_url, "_blank")
    },
  })

  const toast = useToast()
  const excelUpload = useMutation({
    mutationFn: storeApi.postStoreProductsExcel,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: StoreQueryKeys.bulkProduct.lastHistory({ storeId: myInfo?.store || 0 }),
      })

      toast.success("상품 일괄등록이 완료되었어요.")
      close()
    },

    onError: e => {
      console.error(e)
      toast.error("일괄등록에 실패했습니다. 파일 형식을 다시 한번 확인해 주세요.")
      setExcel(undefined)
    },
  })

  const modalStack = useModalStack()

  const openCategoryModal = (type: "download" | "select") => {
    return new Promise<number | undefined>(resolve => {
      const downloadFunction = ({ subCategory }: Category) => {
        mutate({ subCategoryId: subCategory.id })
      }

      const selectFunction = ({ subCategory }: Category) => {
        resolve(subCategory?.id) // select의 경우 resolve에 id를 전달합니다.
      }

      modalStack.open({
        Component: CategorySelectModal,
        key: `bulk-category-${type}-modal`,
        componentProps: {
          onSubmit: type === "download" ? downloadFunction : selectFunction,
          value: undefined,
        },
      })
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    setExcel(file)
    e.target.value = ""
  }

  const onUpload = async () => {
    if (!excel) return

    const subCategory = await openCategoryModal("select")
    if (!subCategory) return

    const formData = new FormData()
    formData.append("file", excel)
    formData.append("product_subcategory", `${subCategory}`)

    excelUpload.mutate({
      storeId: myInfo?.store || 0,
      payload: formData,
    })
  }

  return (
    <div>
      <main className="flex flex-col gap-6 p-5">
        <div className="flex gap-4">
          <div className="flex flex-1 gap-2 bg-[rgba(62,151,255,0.05)] px-4 py-3">
            <WarningOutlineIcon className="mt-0.5 size-4 shrink-0 text-eventColor-travel" />
            <div className="flex flex-col gap-1 text-14 text-eventColor-travel">
              <p>상품 업로드는 한번에 최대 500개까지 업로드 할 수 있어요.</p>
            </div>
          </div>

          <Button
            size="40"
            color="white"
            outline
            className="flex items-center gap-1 font-normal text-gray-700"
            onClick={() => openCategoryModal("download")}
          >
            <DownloadIcon className="size-[14px]" />
            엑셀양식 다운로드
          </Button>
        </div>

        <BulkProductsGuide />
        <div className="flex gap-3">
          <Input type="text" value={excel?.name || ""} readOnly className="flex-1" size="32" />
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} className="hidden" id="file-upload" />
          <Button
            color="dark"
            size="32"
            className="whitespace-nowrap px-4"
            onClick={() => document.getElementById("file-upload")?.click()}
          >
            파일 선택
          </Button>
        </div>
      </main>

      <footer className="flex items-center justify-end gap-2 px-5 py-4">
        <Button size="40" color="gray" className="px-5 font-normal" onClick={close}>
          닫기
        </Button>
        <Button size="40" className="px-5" onClick={onUpload} disabled={excelUpload.isPending}>
          업로드
        </Button>
      </footer>
    </div>
  )
})

export default ExcelBulkProductsModal
