import { useMutation } from "@tanstack/react-query"
import orderApi from "@/apis/order-api"
import { MutationOptions } from "@/types/mutation.type"

export const usePatchOrderResolutionMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: orderApi.patchOrderResolution,
    ...options,
  })
}
