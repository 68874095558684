import { DatePicker, Radio } from "antd"
import { RadioChangeEvent } from "antd/lib"
import dayjs from "dayjs"
import { useTableFilterContext } from "../context/table-filter-context"

const SettlementDatePicker = () => {
  const { dates, setDates, rangeType, setRangeType } = useTableFilterContext()
  const today = dayjs()

  const handleRangeChange = (e: RadioChangeEvent) => {
    const value = e.target.value
    let startDate = today
    let endDate = today

    switch (value) {
      case "week":
        startDate = today.subtract(7, "days")
        break
      case "month":
        startDate = today.subtract(1, "months").add(1, "day")
        break
      case "threeMonth":
        startDate = today.subtract(3, "months").add(1, "day")
        break
      case "custom":
        setRangeType(value)
        return
      default:
        return
    }

    setDates([startDate, endDate])
    setRangeType(value)
  }

  return (
    <div className="flex gap-3">
      <p className="w-[140px]">기간</p>
      <DatePicker.RangePicker
        value={dates}
        onChange={dates => {
          setDates(dates)
          setRangeType("custom")
        }}
      />

      <Radio.Group className="flex items-center" onChange={handleRangeChange} value={rangeType}>
        <Radio value="week">1주일</Radio>
        <Radio value="month">1개월</Radio>
        <Radio value="threeMonth">3개월</Radio>
        <Radio value="custom">직접입력</Radio>
      </Radio.Group>
    </div>
  )
}

export default SettlementDatePicker
