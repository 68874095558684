import React, { useState } from "react"
import { TabsContext } from "@runners/ui"
import withModalLayout from "@/hoc/with-modal-layout"
import ImageStorageGuide from "./image-storage-guide"
import ImageStorageTable from "./image-storage-table"
import UploadSelectedButtons from "./upload-selected-buttons"

export const TAB_LIST = [
  {
    label: "대표 이미지",
    value: "THUMBNAIL",
  },
  {
    label: "추가 이미지",
    value: "ADDITION",
  },
  {
    label: "상세페이지 이미지",
    value: "DETAIL",
  },
] as const

interface ImageStorageModalProps {}

const ImageStorageModal = withModalLayout<ImageStorageModalProps>(_props => {
  // TODO: antd 테이블 selectedRows 주입되는 컴포넌트 만들기
  const [selectedRows, setSelectedRows] = useState<any[]>([])

  return (
    <div className="p-5">
      <ImageStorageGuide />
      <div className="h-2" />

      <TabsContext list={TAB_LIST}>
        <ImageStorageTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        <div className="h-4" />
        <UploadSelectedButtons selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
      </TabsContext>
    </div>
  )
})

export default ImageStorageModal
