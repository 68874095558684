import { PropsWithChildren } from "react"
import { Navigate } from "react-router-dom"
import { getAccessToken } from "./utils/cookie"

const AuthGuard = ({ children }: PropsWithChildren) => {
  const isLogin = getAccessToken()
  const callbackUrl = window.location.pathname + window.location.search

  const href = `/sign-in${callbackUrl ? `?callbackUrl=${encodeURIComponent(callbackUrl)}` : ""}`

  if (!isLogin) {
    return <Navigate to={href} replace />
  }

  return children
}

export default AuthGuard
