"use client"

import { useEffect, useState } from "react"

export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

const useDebounceCallback = (delay = 500) => {
  const [debounceFn, setDebounceFn] = useState<Function>(() => () => {})
  const setDebounceCallback = (fn: Function) => setDebounceFn(() => fn)

  useEffect(() => {
    const handler = setTimeout(() => {
      debounceFn()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [debounceFn, delay])

  return setDebounceCallback
}

export default useDebounceCallback
