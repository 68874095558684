import { Link } from "react-router-dom"
import { InstagramLogoIcon, SenseLogoIcon, YoutubeLogoIcon } from "@/assets/icons"
import cn from "@/utils/cn"

const GITBOOK_URL = "https://terms.sense.im/"
let isNewTab = false

const Footer = () => {
  const left = window?.innerWidth * 0.2
  const width = window?.innerWidth * 0.7
  const height = window?.innerHeight * 0.8

  if (width < 500) {
    isNewTab = true
  }

  const openNewWindow = (url: string) => {
    window.open(url, "_blank")
  }

  const handleOpenGitBook = (key: string) => {
    if (isNewTab) {
      openNewWindow(`${GITBOOK_URL}/${key}`)
    } else {
      window.open(
        `${GITBOOK_URL}/${key}`,
        "_blank",
        `top=0,left=${left},width=${width},height=${height} noopener noreferrer`,
      )
    }
  }

  return (
    <>
      {/* Footer */}
      <footer className="hidden md:block">
        <div className="mx-auto box-border flex items-center justify-center gap-6 border-t border-gray-300 py-2 text-gray-700">
          <p
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
            onClick={() => openNewWindow("https://runners.im")}
          >
            회사소개
          </p>
          <Divider />
          <p
            onClick={() => handleOpenGitBook("sense_service")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            서비스 소개
          </p>
          <Divider />
          <p
            onClick={() => handleOpenGitBook("users/term")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            개인정보 취급방침
          </p>
          <Divider />
          <p
            onClick={() => handleOpenGitBook("users/policy")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            이용약관
          </p>
          <Divider />
          <p
            onClick={() => handleOpenGitBook("users/refund")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            환불정책
          </p>
          <Divider />
          <p
            onClick={() => openNewWindow("https://store.sense.im")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            제휴/입점문의
          </p>
          <Divider />
          <p
            onClick={() => openNewWindow("https://sense.im/api/v1/pdf/purchase-certificate")}
            className="cursor-pointer py-[3px] text-12 hover:text-gray-500"
          >
            구매안전 서비스 가입 사실 확인
          </p>
        </div>

        <div className={cn("flex items-center justify-center gap-6 border-t border-gray-300 py-4")}>
          <div className="flex max-w-5xl flex-1 items-center">
            <SenseLogoIcon className="h-12 shrink-0 text-gray-400" />

            <div className="w-8" />

            <div className="flex flex-1 flex-col gap-1.5">
              <div className="flex gap-[7px] text-gray-500">
                <p className="text-12">주식회사 러너스</p>
                <Divider />
                <p className="text-12">대표 : 김선욱</p>
                <Divider />
                <p className="text-12">사업자등록번호 : 840-81-03234</p>
                <Divider />
                <p className="text-12">통신판매업 신고번호 : 2023-성남분당A-1153</p>
                <Divider />
                <p className="text-12">ⓒ{new Date().getFullYear()} Runners, Inc</p>
              </div>

              <div className="flex gap-[7px] text-gray-500">
                <p className="text-12">대표전화 : 070-7178-8777</p>
                <Divider />
                <p className="text-12">
                  사업장 주소 : 경기도 성남시 분당구 판교로 255번길 9-22, 우림W시티 B동 5층 508-2호
                </p>
                <Divider />
                <p className="text-12">이메일 : support@runners.im</p>
              </div>
            </div>

            <div className="w-6" />

            <div className="flex shrink-0 items-center gap-3">
              <Link
                to="https://www.instagram.com/sense_im_official/"
                target="_blank"
                className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 p-1.5"
              >
                <InstagramLogoIcon className="text-white" />
              </Link>

              <Link
                to="https://www.youtube.com/channel/UC5qTm3jT7p64xZd8qvGaD6A"
                target="_blank"
                className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 p-1.5"
              >
                <YoutubeLogoIcon className="text-white" />
              </Link>
            </div>
          </div>
        </div>
      </footer>

      {/* <MobileFooter /> */}
      <footer className="md:hidden">
        <div className="flex flex-col gap-6 border-t border-t-gray-300 bg-white px-5 py-6">
          <div className="flex flex-col gap-2 text-12 text-gray-600">
            <span className="font-medium">ⓒ{new Date().getFullYear()} Sense, Inc</span>

            <div className="flex items-center gap-1.5">
              <span className="font-medium">주식회사 러너스</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span>대표 김선욱</span>
            </div>
            <div className="flex items-center gap-1.5">
              <span className="font-medium">사업자등록번호</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span>840-81-03234</span>
            </div>
            <div className="flex items-center gap-1.5">
              <span className="font-medium">통신판매업 신고번호</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span>2023-성남분당A-1153</span>
            </div>
            <div className="flex items-center gap-1.5">
              <span className="font-medium">대표전화</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span>070-7178-8777</span>
            </div>
            <div className="flex items-center gap-1.5">
              <span className="font-medium">이메일</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <Link to="mailto:support@runners.im">support@runners.im</Link>
            </div>
            <div className="flex items-center gap-1.5">
              <span className="font-medium">주소</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span>경기도 성남시 분당구 판교역로192번길 16 (삼평동) 8층 806호</span>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <Link to="instagram.com" className="text-[#999999] hover:text-black">
              <InstagramLogoIcon className="size-6" />
            </Link>
            <Link to="youtube.com" className="text-[#999999] hover:text-black">
              <YoutubeLogoIcon className="size-6" />
            </Link>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer

export const Divider = () => {
  return <div className="h-3 w-px bg-gray-300" />
}
