import React, { useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Button, Select } from "antd"
import productApi, { ProductCategoryDto, ProductQueryKeys, ProductSubCategoryDto } from "@/apis/productApi"
import { SearchIcon, XIcon } from "@/assets/icons"
import Loading from "@/components/loading"
import cn from "@/utils/cn"
import { ProductFormFields } from "../detail"

type Category = ProductFormFields["category"]

interface CategorySelectModalProps {
  value?: Category
  onSubmit: (category: Category) => void
  close?: () => void
}

const CategorySelectModal = ({ value, onSubmit, close }: CategorySelectModalProps) => {
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(value?.category?.id)
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | undefined>(value?.subCategory?.id)

  const { data: categoryList = [], isLoading } = useQuery({
    queryKey: ProductQueryKeys.categoryList(),
    queryFn: () => productApi.getProductCategoryList(),
    select: response => response.data,
  })

  const { data: subCategoryList = [], isLoading: isSubLoading } = useQuery({
    queryKey: ProductQueryKeys.subCategoryList(),
    queryFn: () => productApi.getProductSubCategoryList(),
    select: response => response.data,
  })

  const filteredSubCategoryList = useMemo(() => {
    if (!selectedCategory) return subCategoryList

    return subCategoryList.filter(({ product_category }) => product_category === selectedCategory)
  }, [subCategoryList, selectedCategory])

  const searchItemList = useMemo(() => {
    if (!categoryList || !subCategoryList) return []

    return subCategoryList.map(subCategory => ({
      ...subCategory,
      category: categoryList.find(category => category.id === subCategory.product_category),
    }))
  }, [categoryList, subCategoryList])

  const categoryLabel = useMemo(() => {
    if (!selectedSubCategory) return ""

    const item = searchItemList.find(item => item.id === selectedSubCategory)
    return {
      category: item?.category?.title,
      subCategory: item?.title,
    }
  }, [selectedSubCategory])

  const handleSelectChange = (value: string) => {
    const [categoryId, subCategoryId] = value.split("-").map(Number)
    setSelectedCategory(categoryId)
    setSelectedSubCategory(subCategoryId)
  }

  const handleClickCategory = (category: ProductCategoryDto) => {
    setSelectedCategory(prev => (prev === category.id ? undefined : category.id))
    setSelectedSubCategory(undefined)
  }

  const handleClickSubCategory = (subCategory: ProductSubCategoryDto) => {
    setSelectedSubCategory(prev => (prev === subCategory.id ? undefined : subCategory.id))
    setSelectedCategory(subCategory.product_category)
  }

  const handleClickSubmit = () => {
    if (selectedCategory && selectedSubCategory && categoryLabel) {
      onSubmit({
        category: { id: selectedCategory, title: categoryLabel.category || "" },
        subCategory: { id: selectedSubCategory, title: categoryLabel.subCategory || "" },
      })
      close?.()
    }
  }

  return (
    <div className="w-[680px] rounded-2 bg-white">
      <header className="flex justify-between px-5 pb-3 pt-6">
        <h1 className="text-20 font-bold text-gray-900">상품 상세설정</h1>
        <button onClick={close} className="flex size-8 items-center justify-center">
          <XIcon className="size-6" />
        </button>
      </header>
      <div className="border-y border-y-gray-300 p-5">
        <Select
          placeholder="카테고리 검색"
          showSearch
          suffixIcon={<SearchIcon className="size-[18px] text-gray-700" />}
          optionFilterProp="label"
          size="large"
          className="mb-4 w-full"
          onChange={handleSelectChange}
          value={selectedCategory && selectedSubCategory ? `${selectedCategory}-${selectedSubCategory}` : undefined}
          options={searchItemList.map(item => ({
            value: `${item.category?.id}-${item.id}`,
            label: `${item.category?.title} > ${item.title}`,
          }))}
        />

        <div className="flex h-[420px] gap-4 rounded border border-gray-200 px-4 py-2">
          {(isLoading || isSubLoading) && (
            <div className="flex size-full items-center justify-center">
              <Loading />
            </div>
          )}
          {!isLoading && !isSubLoading && (
            <>
              <div className="flex flex-1 flex-col">
                <div className="py-2 text-16 text-gray-800">1차 카테고리</div>
                <div className="my-2 h-px w-full bg-gray-300" />
                <ul className="flex flex-col gap-2 overflow-y-auto">
                  {categoryList.map(category => (
                    <CategoryItem
                      key={category.id}
                      active={selectedCategory === category.id}
                      onClick={() => handleClickCategory(category)}
                    >
                      {category.title}
                    </CategoryItem>
                  ))}
                </ul>
              </div>

              <div className="h-full w-px bg-gray-300" />

              <div className="flex flex-1 flex-col">
                <div className="py-2 text-16 text-gray-800">2차 카테고리</div>
                <div className="my-2 h-px w-full bg-gray-300" />
                <ul className="flex flex-col gap-2 overflow-y-auto">
                  {filteredSubCategoryList.map(subCategory => (
                    <CategoryItem
                      key={subCategory.id}
                      active={selectedSubCategory === subCategory.id}
                      onClick={() => handleClickSubCategory(subCategory)}
                    >
                      {subCategory.title}
                    </CategoryItem>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      <footer className="flex flex-col gap-2 px-5 py-4">
        <div className="flex items-center gap-[5px]">
          {categoryLabel && (
            <div className="flex items-center gap-[5px] text-14 text-gray-600">
              <span>{categoryLabel.category}</span>
              <span>{">"}</span>
              <span className="text-gray-800">{categoryLabel.subCategory}</span>
            </div>
          )}

          {selectedCategory && !selectedSubCategory && <span className="text-14">2차 카테고리를 선택해주세요.</span>}

          {!selectedCategory && !selectedSubCategory && <span className="text-14">카테고리를 선택해주세요.</span>}
        </div>
        <div className="flex justify-end gap-2">
          <Button size="large" type="default" className="border-none bg-gray-100" onClick={close}>
            취소
          </Button>
          <Button
            size="large"
            type="primary"
            className="border-none"
            onClick={handleClickSubmit}
            disabled={!selectedCategory || !selectedSubCategory}
          >
            저장
          </Button>
        </div>
      </footer>
    </div>
  )
}

export default CategorySelectModal

interface CategoryItemProps extends React.HTMLAttributes<HTMLLIElement> {
  active?: boolean
}

export const CategoryItem = ({ active, children, ...props }: CategoryItemProps) => {
  return (
    <li
      {...props}
      className={cn("cursor-pointer rounded px-2.5 py-1.5 text-14 text-gray-700 hover:bg-gray-100", {
        "text-primary": active,
      })}
    >
      {children}
    </li>
  )
}
