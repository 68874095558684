import { Controller, useFormContext, useWatch } from "react-hook-form"
import useDebounceCallback from "@runners/hooks/client/use-debounce"
import { Input, Radio } from "antd"
import FormErrorMessage from "@/components/form/form-error-message"
import useMyInfo from "@/hooks/use-my-info"
import LabelWithItem from "../../components/label-with-item"
import { usePostCombineShippingPolicyTitleCheck } from "../hooks/use-mutation"

const BundleShippingForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const info = useMyInfo()
  const { combine_fee_type, is_extra_fee } = useWatch()

  /**
   * 제목 유효성 검사
   */
  const checkValidate = usePostCombineShippingPolicyTitleCheck()
  const validate = useDebounceCallback(500)

  return (
    <div>
      <LabelWithItem label="이름" required>
        <Controller
          control={control}
          name="title"
          rules={{
            required: "이름을 입력해주세요.",
            validate: value => {
              return new Promise(resolve => {
                validate(async () => {
                  try {
                    await checkValidate.mutateAsync({ title: value, store: info?.store || 0 })
                    resolve(true)
                  } catch (e) {
                    resolve("이미 있는 타이틀입니다.")
                  }
                })
              })
            },
          }}
          render={({ field }) => (
            <Input {...field} status={errors["title"]?.message && "error"} placeholder="이름을 입력해 주세요" />
          )}
        />
        <FormErrorMessage name="title" />
      </LabelWithItem>
      <Divider />

      <Divider />
      <div className="py-1.5 text-14 font-bold text-gray-800">
        <span>배송비 묶음 설정</span>
        <span className="text-error-500">*</span>
      </div>

      <Controller
        name="combine_fee_type"
        control={control}
        rules={{ required: "배송비 묶음 설정을 체크해 주세요." }}
        render={({ field }) => (
          <Radio.Group
            className="flex gap-4"
            value={combine_fee_type}
            options={[
              { label: "배송 정책 중 가장 작은 배송비로 부과", value: "MIN" },
              { label: "배송 정책 중 가장 큰 배송비로 부과", value: "MAX" },
            ]}
            onChange={e => field.onChange(e.target.value)}
          />
        )}
      />
      <div className="h-2" />
      <p className="text-14 text-gray-600">
        묶음배송 그룹 중 ‘무료배송' 상품이 포함된 경우 배송비는 무료로 부과됩니다.
      </p>

      <FormErrorMessage name="combine_fee_type" />

      {combine_fee_type && (
        <>
          <Divider />

          <div className="py-1.5 text-14 font-bold text-gray-800">
            <span>제주/도서산간 추가배송비</span>
            <span className="text-error-500">*</span>
          </div>

          <Controller
            name="is_extra_fee"
            control={control}
            rules={{
              validate: value => (value !== undefined ? true : "도서산간 추가배송비 설정 여부를 체크해 주세요."),
            }}
            render={({ field }) => (
              <Radio.Group
                className="flex gap-4"
                value={field.value}
                options={[
                  { label: "설정함", value: true },
                  { label: "설정안함", value: false },
                ]}
                onChange={e => field.onChange(e.target.value)}
              />
            )}
          />
          <FormErrorMessage name="is_extra_fee" />
        </>
      )}

      {is_extra_fee && (
        <div className="mt-4 flex flex-col gap-4">
          <LabelWithItem label="제주 지역 추가 배송비" required>
            <Controller
              control={control}
              shouldUnregister
              name="jeju_extra_fee"
              rules={{ required: is_extra_fee ? "제주 지역 추가 배송비를 입력해 주세요." : false }}
              render={({ field }) => (
                <Input {...field} status={errors["name"]?.message && "error"} placeholder="이름을 입력해 주세요" />
              )}
            />
            <FormErrorMessage name="jeju_extra_fee" />
          </LabelWithItem>

          <LabelWithItem label="제주 외 도서산간 지역 추가 배송비" required>
            <Controller
              control={control}
              name="etc_extra_fee"
              shouldUnregister
              rules={{ required: is_extra_fee ? "제주 외 도서 산간 지역 추가 배송비를 입력해 주세요." : false }}
              render={({ field }) => (
                <Input {...field} status={errors["name"]?.message && "error"} placeholder="이름을 입력해 주세요" />
              )}
            />
            <FormErrorMessage name="etc_extra_fee" />
          </LabelWithItem>
        </div>
      )}
    </div>
  )
}

export default BundleShippingForm

const Divider = () => <div className="my-4 h-px w-full bg-gray-300" />
