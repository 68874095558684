import { TagChip } from "@runners/ui"

//
const SettlementGuide = () => {
  return (
    <div className="flex justify-center gap-12 rounded-2 border-[3px] border-gray-300 px-6 py-5">
      <div className="flex flex-1 flex-col items-center justify-center">
        <TagChip color="dark" size="32" className="cursor-default text-16" isActive>
          정산주기
        </TagChip>

        <p className="mt-3 whitespace-nowrap text-16 font-bold text-gray-900">주2회 매월 5일, 20일 14:00 순차 지급</p>
        <p className="mt-1 text-14 text-gray-900">(주말/공휴일인 경우 다음 첫 영업일에 지급)</p>
      </div>

      <Divider />

      <div className="flex flex-1 flex-col items-center justify-center">
        <TagChip color="dark" size="32" className="cursor-default text-16" isActive>
          구매확정 기준
        </TagChip>

        <p className="mt-3 text-16 text-gray-900">
          자동구매확정: <b>배송완료일 +3일</b>(영업일기준)
        </p>
        <p className="whitespace-nowrap text-16 text-gray-900">
          구매자 직접 구매확정: 고객이 물품을 수령하고 <b>구매확정한 날 +1일</b>
        </p>
      </div>

      <Divider />

      <div className="flex flex-1 flex-col items-center justify-center">
        <TagChip color="dark" size="32" className="cursor-default text-16" isActive>
          세금계산서 발행
        </TagChip>

        <p className="mt-3 whitespace-nowrap text-center text-16 text-gray-900">
          <b>매월 1~10일 사이 1회만 발행,</b>
          <br />
          <a href={"https://www.popbill.com/"} className="text-eventColor-travel hover:underline" target="_blank">
            www.popbill.com
          </a>
          으로 접속 후 역발행 요청된 세금계산서를 발행하기
        </p>
        <p className="mt-1 text-14 text-gray-900">(팝빌 회원만 전자 세금계산서 역발행 가능)</p>
      </div>
    </div>
  )
}

export default SettlementGuide

const Divider = () => <div className="h-[92px] w-px bg-gray-300" />
