import { useQuery } from "@tanstack/react-query"
import storeApi, { SettlementGroupParams } from "@/apis/storeApi"
import useMyInfo from "@/hooks/use-my-info"

export const useGetSettlementListQuery = (params: SettlementGroupParams) => {
  const user = useMyInfo()

  return useQuery({
    queryKey: ["store", "settlement", "list", { ...params }],
    queryFn: () => storeApi.getStoreSettlementGroups({ storeId: user?.store || 0, params: params }),
    enabled: !!user?.store,
    select: response => response.data,
  })
}

export const useGetSettlementDetailQuery = ({ settlementGroupId }: { settlementGroupId: number }) => {
  return useQuery({
    queryKey: ["store", "settlement", "detail", settlementGroupId],
    queryFn: () => storeApi.getStoreSettlementGroup({ settlementGroupId }),
    select: response => response.data,
  })
}
