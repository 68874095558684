import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Button, Dropdown, MenuProps, Table } from "antd"
import storeApi, { StoreQueryKeys } from "@/apis/storeApi"
import { KebabBoldIcon, StoreFrontIcon } from "@/assets/icons"
import { useAlert } from "@/hooks/use-alert"
import useModalStack from "@/hooks/use-modal"
import useMyInfo from "@/hooks/use-my-info"
import useToast from "@/hooks/use-toast"

import BundleShippingModal from "./components/bundle-shipping-modal"
import { useDeleteCombineShippingPolicy, usePostCombineShippingPolicyCopy } from "./hooks/use-mutation"
import DashboardHeader from "../../components/dashboard-header"

const BREADCRUMB_ITEMS = [
  {
    title: (
      <div className="flex items-center gap-1">
        <StoreFrontIcon className="size-3" />
        <span>상품관리</span>
      </div>
    ),
    href: "/dashboard/products",
  },
  { title: "배송비 묶음그룹 관리" },
]

const moreMenu: MenuProps["items"] = [
  { key: "edit", label: "수정하기" },
  { key: "copy", label: "복제하기" },
  { key: "delete", label: "삭제하기", danger: true },
]

const ShippingGroupPolicyPage = () => {
  const myInfo = useMyInfo()
  const toast = useToast()
  const alert = useAlert()
  const modalStack = useModalStack()
  const queryClient = useQueryClient()

  const storeId = myInfo?.store || 0

  const shippingPolicyListParams = {
    storeId: myInfo?.store || 0,
    params: {
      page_size: 50,
    },
  }

  const { data: shippingPolicyList = [], isLoading } = useQuery({
    queryKey: StoreQueryKeys.combineShippingPolicyList(shippingPolicyListParams),
    queryFn: () => storeApi.getCombineShippingPolicyList(shippingPolicyListParams),
    enabled: !!myInfo?.store,
    select: response => response.data,
  })

  /** ------------------------------------------------------------------------------
   * 
   * mutation
   * 
   ------------------------------------------------------------------------------ */
  const postCopyMutation = usePostCombineShippingPolicyCopy({
    onSuccess: () => {
      toast.success("배송정보를 복제했습니다.")
      queryClient.invalidateQueries({ queryKey: StoreQueryKeys.combineShippingPolicyList({ storeId }) })
    },
    onError: error => {
      toast.error("배송정보 복제에 실패했습니다.")
      console.error(error)
    },
  })

  const deleteShippingPolicyMutation = useDeleteCombineShippingPolicy({
    onSuccess: () => {
      toast.success("배송정보를 삭제했습니다.")
      queryClient.invalidateQueries({ queryKey: StoreQueryKeys.combineShippingPolicyList({ storeId }) })
    },
    onError: error => {
      toast.error("배송정보 삭제에 실패했습니다.")
      console.error(error)
    },
  })

  /**
   * 생성/수정 모달
   */
  const handleAddPolicyClick = (shippingPolicyId?: number) => {
    modalStack.open({
      key: `combine-shipping-modal-${shippingPolicyId || "add"}`,
      Component: BundleShippingModal,
      componentProps: { id: shippingPolicyId },
      disableBackdropClick: true,
    })
  }

  /**
   * more dot 클릭이벤트
   */
  const handleMenuClick =
    (combineShippingPolicyId: number) =>
    ({ key }: { key: string }) => {
      switch (key) {
        case "edit":
          handleAddPolicyClick(combineShippingPolicyId)
          return
        case "copy":
          postCopyMutation.mutate({ combineShippingPolicyId })
          return

        case "delete":
          alert.open({
            title: "배송 묶음 정보를 삭제하시겠습니까?",
            content: "삭제하시면 복구할 수 없습니다.",
            onConfirm: () => deleteShippingPolicyMutation.mutate({ combineShippingPolicyId }),
          })
          return
        default:
          return
      }
    }

  /** ------------------------------------------------------------------------------
   * 
   * antd 테이블
   * 
   ------------------------------------------------------------------------------ */
  const columns = [
    {
      key: "title",
      title: "이름",
      dataIndex: "title",
      width: "40%",
    },
    {
      key: "combine_fee_type",
      title: "배송비 계산방식",
      dataIndex: "combine_fee_type",
      width: "30%",
    },
    {
      key: "policy_number",
      title: "No.",
      dataIndex: "policy_number",
      width: "30%",
      render: (policyNumber: string) => (
        <div
          onDoubleClick={() => {
            navigator.clipboard.writeText(policyNumber)
            toast.success(`${policyNumber} 복사되었습니다.`)
          }}
          className="text-14 font-medium"
        >
          {policyNumber}
        </div>
      ),
    },
    {
      key: "more",
      title: "",
      dataIndex: "more",
      render: (_: any, record: any) => {
        return (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: moreMenu,
              onClick: handleMenuClick(record.id),
            }}
          >
            <button className="flex size-10 shrink-0 items-center justify-center rounded text-gray-700 hover:bg-gray-200">
              <KebabBoldIcon className="size-6" />
            </button>
          </Dropdown>
        )
      },
    },
  ]

  const dataSource = shippingPolicyList.map(delivery => ({
    ...delivery,
    combine_fee_type: delivery.combine_fee_type === "MIN" ? "최소부과" : "최대부과",
    key: delivery.id,
  }))

  return (
    <div>
      <DashboardHeader title="배송비 묶음그룹 관리" breadcrumbItems={BREADCRUMB_ITEMS} />
      <div className="p-6 pb-20">
        <div className="flex justify-between">
          <h4 className="text-20 font-bold text-gray-900">배송비 묶음그룹</h4>
          <Button type="primary" onClick={() => handleAddPolicyClick()}>
            배송비 묶음그룹 추가하기
          </Button>
        </div>

        <div className="my-6 h-px w-full bg-gray-300" />
        <p className="text-end text-12 text-gray-700">No. 를 더블클릭하시면 복사됩니다.</p>

        <Table columns={columns} dataSource={dataSource} loading={isLoading} />
      </div>
    </div>
  )
}

export default ShippingGroupPolicyPage
