const BulkProductsGuide = () => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-18 font-bold text-gray-900">센스 스토어 일괄등록 가이드</h3>

      <p className="text-16 font-medium text-gray-800">
        1. 먼저 메인 화면에서 [이미지 업로드] 버튼을 눌러 등록할 상품의 대표 이미지, 추가 이미지, 상세 페이지를 먼저
        업로드해 주세요.
      </p>

      <p className="text-16 font-medium text-gray-800">
        2. 메인 화면에서 [엑셀 일괄등록] 버튼을 누르고, [엑셀양식 다운로드] 버튼을 눌러 일괄등록할 상품의 카테고리를
        선택한 후, 해당하는 일괄등록 엑셀 파일 양식을 다운로드해 주세요.
      </p>

      <p className="text-16 font-medium text-gray-800">
        3. 일괄등록 엑셀파일에서 각 항목에 해당하는 상품 정보를 입력해 주세요.
        <span className="mt-1 block text-14 font-normal">
          *다운로드 하신 엑셀파일에서는{" "}
          <span className="text-error-500">선택하신 카테고리의 상품정보고시 내용이 노출</span>됩니다.
          <br />
          (상세페이지에 정보가 있다면, “상품 상세 참조”로 일괄 입력해주셔도 무방합니다.)
        </span>
        <span className="mt-1 block text-14 font-normal text-error-500">
          * 엑셀 양식에서 예시를 참고하여 8행부터 상품 정보를 입력해 주시기 바랍니다.
        </span>
      </p>

      <p className="text-16 font-medium text-gray-800">
        4. 엑셀 파일 입력을 마치셨다면, 아래의 엑셀 파일 업로드 기능을 통해 파일을 업로드해 주세요.
      </p>
    </div>
  )
}

export default BulkProductsGuide
