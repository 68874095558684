import { get, useFormContext } from "react-hook-form"
import cn from "@/utils/cn"

interface FormErrorMessageProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: string
}

const FormErrorMessage = ({ name, className, ...restProps }: FormErrorMessageProps) => {
  const methods = useFormContext()
  const error = get(methods.formState.errors, name)

  if (!error) {
    return null
  }

  return (
    <span {...restProps} className={cn("m-0.5 text-14 text-red-500", className)}>
      {error.message}
    </span>
  )
}

export default FormErrorMessage
