import React from "react"
import { useNavigate } from "react-router-dom"
import { useGetGuideListQuery, GuideListDto } from "@/apis/guideApi"

const TipsPage = () => {
  const nav = useNavigate()
  const { data: guideResponse } = useGetGuideListQuery({ type: "TIP" })

  if (!guideResponse || !guideResponse.length) return <p></p>

  const handleClick = (id: number) => {
    nav(`/tips/${id}`)
  }

  return (
    <div className="mx-auto w-full max-w-[1080px] py-20 mobile:px-5">
      <div className="flex flex-col gap-[100px] mobile:justify-center">
        <p className="flex items-center justify-center text-34 font-bold text-[#21222E]">판매활용 Tip</p>
        <div className="mx-auto flex flex-wrap gap-8 mobile:flex-col mobile:flex-nowrap">
          {guideResponse.map((item: GuideListDto) => (
            <div
              key={item.id}
              onClick={() => handleClick(item.id)}
              className="relative flex h-[320px] w-[246px] cursor-pointer flex-col overflow-hidden rounded-4 border border-solid border-[#E8E8F0] bg-white mobile:h-[122px] mobile:w-[335px]"
            >
              {item.thumbnail_image_url && (
                <img src={item.thumbnail_image_url} alt={item.title} className="size-full object-cover" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TipsPage
