import React, { useState } from "react"
import { useGetNoticeQuery } from "@/apis/noticeApi"
import { CaretUpIcon } from "@/assets/icons"
import cn from "@/utils/cn"

const NoticeContent = ({ noticeId, title }: { noticeId: number; title: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { data: guideData } = useGetNoticeQuery(noticeId, {
    enabled: isExpanded,
  })

  return (
    <div
      className={cn(
        "cursor-pointer rounded-3 bg-gray-100 px-[34px] py-6 transition-all active:bg-gray-200 md:hover:bg-gray-200",
        "w-[1080px] mobile:w-[335px]",
      )}
      onClick={() => {
        setIsExpanded(prev => !prev)
      }}
    >
      <div className="flex items-center justify-between">
        <p className="text-16 font-bold md:text-18">{title}</p>
        <CaretUpIcon
          className={cn("size-6 transition duration-300 ease-in-out", {
            "rotate-180": !isExpanded,
          })}
        />
      </div>

      {isExpanded && (
        <div className="max-h-screen translate-y-0 overflow-y-hidden opacity-100 transition-all duration-300 ease-in-out">
          <div
            className="tiptap mt-5 text-16 text-gray-800 [&_p]:min-h-4"
            dangerouslySetInnerHTML={{ __html: guideData?.data.content || "" }}
          />
        </div>
      )}
    </div>
  )
}

export default NoticeContent
