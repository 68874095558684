import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Select, Table } from "antd"
import { TableProps } from "antd/lib"
import useModalStack from "@/hooks/use-modal"
import OrderResolutionModal from "../../orders/modal/order-resolution-modal"
import { useGetOrderResolutionsQuery } from "../_hooks/use-get-query"

interface CancelTableProps {}

const CancelTable = (props: CancelTableProps) => {
  const {} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1
  const pageSize = searchParams.get("page_size") ? Number(searchParams.get("page_size")) : 10
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const handlePageSizeChange = (size: number) => {
    searchParams.set("page_size", size.toString())
    setSearchParams(searchParams)
  }

  const modalStack = useModalStack()
  const openOrderDetail = (orderResolutionId: number) => {
    modalStack.open({
      key: `order-detail-${orderResolutionId}`,
      Component: OrderResolutionModal,
      componentProps: {
        orderResolutionId: orderResolutionId,
        title: "취소 주문건",
        layoutClassName: "w-[680px]",
      },
    })
  }

  /**
   * column
   */
  const columns: TableProps<any>["columns"] = [
    {
      key: "order_number",
      title: "주문번호",
      dataIndex: "order_number",
      render: (orderNumber, record) => {
        const { id } = record
        return (
          <button onClick={() => openOrderDetail(id)} className="text-eventColor-travel hover:underline">
            {orderNumber}
          </button>
        )
      },
    },
    // { key: "product_number", title: "상품번호", dataIndex: "product_number" },
    { key: "ordered_at", title: "주문일자", dataIndex: "ordered_at" },
    { key: "status", title: "상태", dataIndex: "status" },
    { key: "order_title", title: "상품명", dataIndex: "order_title" },
    { key: "option_name", title: "옵션명", dataIndex: "option_name" },
    { key: "count", title: "수량", dataIndex: "count" },
    { key: "price", title: "결제 금액", dataIndex: "price" },
    { key: "payment_method", title: "결제 방법", dataIndex: "payment_method" },
    { key: "orderer_name", title: "주문자", dataIndex: "orderer_name" },
    { key: "receiver_name", title: "수령인", dataIndex: "receiver_name" },
  ]

  const { data = [] } = useGetOrderResolutionsQuery({
    page,
    page_size: pageSize,
    order_status: "CANCEL",
  })

  const dataSource = data.map(v => {
    return {
      id: v.id,
      status: "주문 취소",
      order_number: v.order_number,
      order_title: v.order_title,
      ordered_at: v.ordered_at,
      orderer_name: v.orderer_name,
      payment_method: v.payment_method,
      price: v.price,
      receiver_name: v.receiver_name,
    }
  })

  return (
    <div>
      <div className="flex items-center justify-between">
        <h4 className="mb-6 text-20 font-bold text-gray-900">목록</h4>

        <div className="flex items-center gap-2">
          <Select
            className="w-[127px]"
            options={[
              { label: "10개씩 보기", value: 10 },
              { label: "50개씩 보기", value: 50 },
              { label: "100개씩 보기", value: 100 },
              { label: "200개씩 보기", value: 200 },
              { label: "500개씩 보기", value: 500 },
            ]}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
        }}
      />
    </div>
  )
}

export default CancelTable
