import { DogTrashIcon } from "@/assets/character"

interface EmptyTrashProps {
  label: string
}

export const EmptyTrash = (props: EmptyTrashProps) => {
  const { label } = props

  return (
    <div className="flex flex-col items-center gap-2.5">
      <DogTrashIcon className="size-[180px]" />
      <p className="text-18 font-bold text-gray-800">{label}</p>
    </div>
  )
}
