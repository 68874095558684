import { createDynamicContext } from "@/hooks/create-dynamic-context"

interface CancelReturnContextProps {
  isCancel: boolean
  orderResolutionId: number
}

const { ContextProvider, useContext } = createDynamicContext<CancelReturnContextProps>()

export const useCancelReturnContext = useContext
export const CancelReturnContextProvider = ContextProvider
