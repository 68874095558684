import React from "react"
import { cn } from "@runners/lib/utils"
import { cva } from "class-variance-authority"
import { TagChipProps } from "./tag-chip"

const tagChipColorVariants = cva(
  "flex w-fit cursor-pointer items-center justify-center whitespace-nowrap rounded-full px-4 transition-all duration-200 ease-in-out",
  {
    variants: {
      color: {
        "#FFBD14": "bg-[#FFBD14] text-white",
        "#9758FF": "bg-[#9758FF] text-white",
        "#FF6767": "bg-[#FF6767] text-white",
        "#00DAC0": "bg-[#00DAC0] text-white",
        "#14BD2F": "bg-[#14BD2F] text-white",
        "#FF5C8C": "bg-[#FF5C8C] text-white",
        "#3E97FF": "bg-[#3E97FF] text-white",
      },
      isActive: {
        true: "border border-transparent font-bold",
        false: "border border-gray-300",
      },
      size: {
        "24": "py-[3px] text-12",
        "28": "py-[5px] text-12",
        "32": "py-1.5 text-14",
        "40": "py-2 text-16",
      },
    },
    compoundVariants: [
      {
        color: "#FFBD14",
        isActive: true,
        className: "bg-[#FFBD14] text-white",
      },
      {
        color: "#FFBD14",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#9758FF",
        isActive: true,
        className: "bg-[#9758FF] text-white",
      },
      {
        color: "#9758FF",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#FF6767",
        isActive: true,
        className: "bg-[#FF6767] text-white",
      },
      {
        color: "#FF6767",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#00DAC0",
        isActive: true,
        className: "bg-[#00DAC0] text-white",
      },
      {
        color: "#00DAC0",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#14BD2F",
        isActive: true,
        className: "bg-[#14BD2F] text-white",
      },
      {
        color: "#14BD2F",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#FF5C8C",
        isActive: true,
        className: "bg-[#FF5C8C] text-white",
      },
      {
        color: "#FF5C8C",
        isActive: false,
        className: "bg-white text-gray-600",
      },
      {
        color: "#3E97FF",
        isActive: true,
        className: "bg-[#3E97FF] text-white",
      },
      {
        color: "#3E97FF",
        isActive: false,
        className: "bg-white text-gray-600",
      },
    ],
  },
)

export type ColorfulType =
  | "#FFBD14"
  | "#9758FF"
  | "#FF6767"
  | "#00DAC0"
  | "#14BD2F"
  | "#FF6767"
  | "#FFBD14"
  | "#00DAC0"
  | "#FF5C8C"
  | "#FF6767"
  | "#FFBD14"
  | "#3E97FF"
  | "#3E97FF"
  | "#FFBD14"
  | "#FF6767"
  | "#FF5C8C"
  | "#FFBD14"
  | "#00DAC0"
  | "#3E97FF"
  | "#00DAC0"
  | "#14BD2F"

export interface TagChipColorProps extends Omit<TagChipProps, "color"> {
  color: ColorfulType
}
/**
 * 이벤트 추천용 Chip UI
 * 컬러풀합니다
 */
const TagChipColor = React.forwardRef<HTMLButtonElement, TagChipColorProps>(
  ({ color, size, isActive, children, className, ...props }: TagChipColorProps, ref) => {
    return (
      <button
        ref={ref}
        className={cn(tagChipColorVariants({ color, isActive, size }), className)}
        type="button"
        {...props}
      >
        {children}
      </button>
    )
  },
)

TagChipColor.displayName = "TagChipColor"

export default TagChipColor
