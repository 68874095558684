import { useState } from "react"
import "@runners/editor/dist/style.css"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { useGetGuideListQuery } from "@/apis/guideApi"
import { SearchIcon } from "@/assets/icons"
import FaqContent from "./detail"

type Category = "자주 묻는 질문" | "정산" | "주문/취소" | "상품" | "판매 가이드"

const categories: Category[] = ["자주 묻는 질문", "정산", "주문/취소", "상품", "판매 가이드"]

const subTypes: Record<Category, string> = {
  "자주 묻는 질문": "COMMON_QUESTION",
  정산: "SETTLEMENT",
  "주문/취소": "ORDER_CANCEL",
  상품: "PRODUCT",
  "판매 가이드": "SELLING_GUIDE",
}
const FaqPage = () => {
  const [searchWord, setSearchWord] = useState("")
  const [activeCategory, setActiveCategory] = useState<Category>("자주 묻는 질문")

  const { data: faqCategory = [] } = useGetGuideListQuery({
    type: "FAQ",
    sub_type: subTypes[activeCategory],
    search: searchWord,
  })

  const handleCategoryClick = (category: Category) => setActiveCategory(category)

  return (
    <div className="mx-auto flex w-[1080px] flex-col py-20 mobile:w-[375px] mobile:px-5 mobile:py-10">
      <h1 className="mb-[100px] text-center text-3xl font-bold text-[#21222E] mobile:mb-[60px]">센스 스토어 FAQ</h1>
      <div className="relative mx-auto mb-20 flex w-[550px] mobile:mb-12 mobile:w-[275px]">
        <input
          type="text"
          placeholder="궁금하신 사항을 검색해보세요"
          value={searchWord}
          onChange={e => setSearchWord(e.target.value)}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 text-lg mobile:text-16 mobile:leading-[22px]"
        />
        <SearchIcon className="absolute right-4 top-4 cursor-pointer text-lg" />
      </div>

      <div className="mb-10 flex items-center justify-center mobile:mb-6">
        <Swiper spaceBetween={8} slidesPerView="auto">
          {categories.map(category => (
            <SwiperSlide key={category} className="w-[140px] mobile:w-auto">
              <button
                className={`w-full rounded-full border border-solid border-[#E8E8F0] px-3 py-2 text-16 font-medium ${
                  activeCategory === category ? "bg-[#424350] font-bold text-white" : "bg-white text-gray-700"
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="space-y-4">
        {faqCategory.length > 0 ? (
          faqCategory.map(({ id, title }) => <FaqContent key={id} guideId={id} title={title} />)
        ) : (
          <div className="text-center text-gray-500">{searchWord}에 대한 결과가 없습니다.</div>
        )}
      </div>
    </div>
  )
}

export default FaqPage
