export const ACCESS_TOKEN_KEY = "_st"
export const REFRESH_TOKEN_KEY = "_rt"

// interface CookieSetOptions {
//   path?: string
//   expires?: Date
//   maxAge?: number
//   domain?: string
//   secure?: boolean
//   httpOnly?: boolean
//   sameSite?: boolean | "none" | "lax" | "strict"
//   partitioned?: boolean
// }
export const getCookie = (cName: string): string => {
  const name = `${cName}=`
  const ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const setCookie = (cName: string, cValue: string): void => {
  const expiration = new Date()
  expiration.setTime(new Date().getTime() + 3600000 * 24 * 30 * 3 /*7*/)
  document.cookie = `${cName}=${cValue};Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`
}

export const removeCookie = (cName: string): void => {
  const expiration = new Date()
  expiration.setTime(0)
  document.cookie = `${cName}=;Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`
}

export const getAccessToken = () => {
  return getCookie(ACCESS_TOKEN_KEY)
}

export const setAccessToken = (accessToken: string) => {
  setCookie(ACCESS_TOKEN_KEY, accessToken)
}

export const removeAccessToken = () => {
  removeCookie(ACCESS_TOKEN_KEY)
}

export const getRefreshToken = () => {
  return getCookie(REFRESH_TOKEN_KEY)
}

export const setRefreshToken = (refreshToken: string) => {
  setCookie(REFRESH_TOKEN_KEY, refreshToken)
}

export const removeRefreshToken = () => {
  removeCookie(REFRESH_TOKEN_KEY)
}
