import React from "react"
import { XIcon } from "@/assets/icons"
import cn from "@/utils/cn"

// 기본 모달 레이아웃에 필요한 필수 props 정의
interface ModalLayoutBaseProps {
  title: string
  layoutClassName?: string
}

// HOC에서 사용할 props 타입 정의, T와 기본 레이아웃 props를 결합
type WithModalLayoutProps<T> = T &
  ModalLayoutBaseProps & {
    close: () => void | Promise<void>
  }

// HOC 구현
const withModalLayout = <T,>(Component: React.ComponentType<WithModalLayoutProps<T>>) => {
  return (props: WithModalLayoutProps<T>) => {
    return (
      <div className={cn("w-[490px] rounded-2 bg-white shadow-lg", props.layoutClassName)}>
        <header className="flex justify-between border-b border-gray-300 px-5 pb-3 pt-6">
          <h1 className="text-20 font-bold">{props.title}</h1>
          <button onClick={props.close} className="flex size-8 items-center justify-center">
            <XIcon className="size-6" />
          </button>
        </header>

        <Component {...props} />

        {/* footer는 콜백함수 전달 문제로 각 부분에서 구현하는게 맞는듯! */}
      </div>
    )
  }
}

export default withModalLayout
