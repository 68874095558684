import { type ClassValue, clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      borderRadius: [...new Array(96)].map((_, i) => ((i + 1) * 0.5).toString()),
    },
    classGroups: {
      "font-size": [{ text: ["12", "14", "16", "18", "20", "24", "28", "34", "40"] }],
      rounded: [
        {
          borderRadius: [...new Array(96)].map((_, i) => ((i + 1) * 0.5).toString()),
        },
      ],
    },
  },
})

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export default cn
