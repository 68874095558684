import { OrderStatus, toLocalePrice } from "@runners/lib"
import { Button, Image } from "@runners/ui"
import { useGetOrderResolutionQuery } from "../../../order-cancel/_hooks/use-get-query"
import { useCancelReturnContext } from "../../_hooks/use-cancel-return-context"

interface OrderResolutionDetailInfoProps {
  // 품절로 인한 주문 취소 버튼 클릭이벤트
  onCancelSoldOut?: () => void
}

const OrderResolutionDetailInfo = (props: OrderResolutionDetailInfoProps) => {
  const { onCancelSoldOut } = props

  const { isCancel, orderResolutionId } = useCancelReturnContext()
  const { data } = useGetOrderResolutionQuery({ orderResolutionId })

  if (!data) return null

  const { order_product_group, order_resolution_choice, exchange_product_option_title, detail_reason, images_data } =
    data
  const { title, total_price, image_url, order_products, status } = order_product_group

  return (
    <div className="rounded-2 border border-gray-300 p-5">
      {!isCancel && <p className="mb-4 text-16 font-bold text-success-500">{"교환/반품중"}</p>}

      <div className="flex flex-1 flex-col gap-3">
        <div className="flex w-full gap-4">
          <Image src={image_url} size="375" alt="test" className="size-20 shrink-0 rounded-1 border border-gray-200" />

          <div className="grow">
            <p className="line-clamp-1 text-ellipsis text-16 font-medium text-gray-900">{title}</p>

            {order_products.map(p => {
              return (
                <div key={p.id} className="mt-1 flex items-center gap-2 text-14 text-gray-600">
                  <p className="line-clamp-1 text-ellipsis">{p.title}</p>
                  <div className="h-2.5 w-px bg-gray-300" />
                  <p className="shrink-0">{p.count}개</p>
                </div>
              )
            })}
          </div>

          <p className="mt-3 flex shrink-0 items-center justify-center text-18 font-bold text-gray-900">
            {toLocalePrice(total_price, "원")}
          </p>
        </div>

        {status !== OrderStatus.CANCEL_COMPLETE && onCancelSoldOut && (
          <div className="flex items-center justify-end">
            <Button color="primary" size="36" className="px-4" onClick={onCancelSoldOut}>
              품절로 인한 주문취소
            </Button>
          </div>
        )}

        {!!exchange_product_option_title && (
          <div className="flex flex-col gap-2">
            <p className="text-14 font-bold text-gray-900">교환 옵션</p>
            <div className="rounded-1 border border-gray-300 px-4 py-2.5 text-14 text-gray-700">
              {exchange_product_option_title}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <p className="text-14 font-bold text-gray-900">{isCancel ? "취소" : "교환/반품"} 사유</p>
          <div className="rounded-1 border border-gray-300 px-4 py-2.5 text-14 text-gray-700">
            {order_resolution_choice.title}
          </div>
        </div>

        {!!detail_reason && (
          <div className="flex flex-col gap-2">
            <p className="text-14 font-bold text-gray-900">상세 사유</p>
            <div className="min-h-[100px] whitespace-pre rounded-1 border border-gray-300 px-4 py-2.5 text-14 text-gray-700">
              {detail_reason}
            </div>
          </div>
        )}

        {images_data.length > 0 && (
          <div className="flex flex-col gap-2">
            <p className="text-14 font-bold text-gray-900">사진</p>
            <div className="flex gap-2">
              {images_data.map((v, i) => {
                return (
                  <Image
                    key={`${v}_${i}번째`}
                    src={v}
                    size="375"
                    alt={`${i}번째 사진`}
                    className="size-20 rounded-1 bg-gray-100"
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderResolutionDetailInfo
