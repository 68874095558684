import { ToastOptions, toast } from "react-hot-toast"

import { ToastSuccessIcon, ToastWarningIcon, ToastErrorIcon } from "@/assets/icons"
import cn from "@/utils/cn"

/** ------------------------------------------------------------------------------
 * 
 * react-hot-toast 사용
 * 
 ------------------------------------------------------------------------------ */

/**
 * 기본 토스트의 지속 시간입니다
 */
const DURATION = {
  success: 2000,
  warning: 3000,
  error: 3000,
}

interface SenseToastOptions extends ToastOptions {
  outline?: boolean
}

type ToastFunctionType = (message: string, options?: SenseToastOptions) => void

/**
 * 기본 토스트의 스타일을 만들어주는 클래스네임입니다.
 */
const toastClassNames = {
  common: "toast !w-screen !max-w-[416px] shadow-[0px_0px_4px_0px_rgba(0,0,0,0.14)] !border !rounded-0 mobile:!text-14",
  default: {
    color: "!text-white !border-gray-900 !bg-gray-900",
    // outline: "",
    icon: "",
  },
  success: {
    color: "!text-success-500 !border-success-300 !bg-white",
    // outline: "",
    icon: "",
  },
  warning: {
    color: "!text-warning-600 !border-warning-200 !bg-white",
    // outline: "",
    icon: "",
  },

  error: {
    color: "!text-error-500 !border-error-200 !bg-white",
    // outline: "",
    icon: "",
  },
}

const useToast = () => {
  /**
   * default Toast
   */
  const common: ToastFunctionType = (message, options) => {
    // const isOutline = options?.outline ?? false
    toast.success(message, {
      duration: DURATION.success,
      icon: <div className="hidden"></div>,
      className: cn([toastClassNames.common], [toastClassNames.default.color], options?.className),
      ...options,
    })
  }

  /**
   * success Toast
   */
  const success: ToastFunctionType = (message, options) => {
    // const isOutline = options?.outline ?? false

    toast.success(message, {
      duration: DURATION.success,
      icon: ToastIcons({ type: "success" }),
      className: cn([toastClassNames.common], [toastClassNames.success.color], options?.className),
      ...options,
    })
  }

  /**
   * warning Toast
   */
  const warning: ToastFunctionType = (message, options) => {
    toast.error(message, {
      duration: DURATION.warning,
      icon: ToastIcons({ type: "warning" }),
      className: cn([toastClassNames.common], [toastClassNames.warning.color], options?.className),
      ...options,
    })
  }

  /**
   * error Toast
   */
  const error: ToastFunctionType = (message, options) => {
    toast.error(message, {
      duration: DURATION.error,
      icon: ToastIcons({ type: "error" }),
      className: cn([toastClassNames.common], [toastClassNames.error.color], options?.className),
      ...options,
    })
  }

  return { common, success, warning, error }
}

export default useToast

interface ToastIconsProps {
  type: "success" | "warning" | "error"
  // outline?: boolean
}

const ToastIcons = ({ type }: ToastIconsProps) => {
  const IconRender = () => {
    switch (type) {
      case "success":
        return <ToastSuccessIcon className="size-full" />
      case "warning":
        return <ToastWarningIcon className="size-full" />
      case "error":
        return <ToastErrorIcon className="size-full" />
      default:
        return <></>
    }
  }

  return (
    <div className="flex size-6 shrink-0 items-center justify-center">
      <IconRender />
    </div>
  )
}
