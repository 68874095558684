import { useSearchParams } from "react-router-dom"
import { Checkbox, CheckboxProps } from "antd"

enum PaymentStatus {
  "신용/체크카드" = "신용/체크카드",
  "계좌이체" = "계좌이체",
  "휴대폰결제" = "휴대폰결제",
  "가상계좌" = "가상계좌",
  "무통장입금" = "무통장입금",
}

const statusList = Object.entries(PaymentStatus).map(([key, value]) => ({
  label: key,
  value,
}))

const STATUS_KEY = "payment_type"

const PaymentFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get(STATUS_KEY) ? searchParams.get(STATUS_KEY)!.split(",") : []

  const checkAll = !status.length

  const handleCheckAllChange: CheckboxProps["onChange"] = () => {
    searchParams.delete(STATUS_KEY)
    setSearchParams(searchParams)
  }

  const handleCheckboxChange: CheckboxProps["onChange"] = e => {
    const { value, checked } = e.target

    let updateStatus

    if (checked) {
      updateStatus = [...status, value]
    } else {
      updateStatus = checkAll
        ? statusList.map(({ value }) => value).filter(v => v !== value)
        : status.filter(v => v !== value)
    }

    if (updateStatus.length === statusList.length) {
      searchParams.delete(STATUS_KEY)
    } else {
      searchParams.set(STATUS_KEY, updateStatus.join(","))
    }
    setSearchParams(searchParams)
  }

  return (
    <div className="flex items-center gap-5 [&_.ant-checkbox+span]:pr-0">
      <span className="w-[140px] text-14 text-gray-900">결제방법</span>

      <Checkbox checked={checkAll} onChange={handleCheckAllChange}>
        전체
      </Checkbox>

      {statusList.map(({ value, label }) => (
        <Checkbox
          key={value}
          value={value}
          checked={checkAll || status.includes(value)}
          onChange={handleCheckboxChange}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  )
}

export default PaymentFilter
