import { useMutation } from "@tanstack/react-query"
import storeApi from "@/apis/storeApi"
import { MutationOptions } from "@/types/mutation.type"

/**
 * 다중 이미지 생성
 */
export const useStoreUploadImagesMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postStoreImages,
    ...options,
  })
}

/**
 * 스토어 이미지 복제
 */
export const useStoreImagesCopyMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postStoreImagesCopy,
    ...options,
  })
}

/**
 * 스토어 이미지 선택 삭제
 */
export const useDeleteStoreImagesMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.deleteStoreImages,
    ...options,
  })
}
