import { useKeydownFunction } from "@runners/hooks"
import { Button } from "antd"
import cn from "@/utils/cn"

export interface AlertProps {
  title?: React.ReactNode
  content?: React.ReactNode
  className?: string
  cancelText?: string
  okText?: string
  submitOnly?: boolean
  messageOnly?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  close?: () => void

  enabledEscClose?: boolean
  enabledEnterConfirm?: boolean
}

const Alert = ({
  title,
  content,
  className,
  cancelText,
  okText,
  submitOnly = false,
  messageOnly = false,
  onConfirm,
  onCancel,
  close = () => {},
  enabledEscClose = false,
  enabledEnterConfirm = false,
}: AlertProps) => {
  const handleClickConfirm = () => {
    onConfirm?.()
    close?.()
  }

  const handleClickCancel = () => {
    onCancel?.()
    close?.()
  }

  useKeydownFunction({
    fn: close,
    key: "Escape",
    enabled: enabledEscClose,
  })

  useKeydownFunction({
    fn: handleClickConfirm,
    key: "Enter",
    enabled: enabledEnterConfirm,
  })

  return (
    <div
      className={cn("flex w-[314px] flex-col items-center gap-8 rounded-2 bg-white p-4 pt-6 shadow-popup", className)}
    >
      <div className="flex w-full flex-col gap-3">
        {typeof title === "string" ? (
          <span className="line-clamp-2 text-center text-18 font-bold text-gray-900">
            {title.split("\n").map(text => {
              return (
                <span key={text}>
                  {text}
                  <br />
                </span>
              )
            })}
          </span>
        ) : (
          title
        )}
        {typeof content === "string" ? (
          <span className="whitespace-pre-wrap text-center text-14 font-medium text-gray-700">{content}</span>
        ) : (
          content
        )}
      </div>

      {!messageOnly && (
        <div className="flex w-full gap-2">
          {!submitOnly && (
            <Button type="default" className="flex-1 border-none bg-gray-100" onClick={handleClickCancel}>
              {cancelText || "취소"}
            </Button>
          )}
          <Button type="primary" className="h-10 flex-1 border-none" onClick={handleClickConfirm}>
            {okText || "확인"}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Alert
