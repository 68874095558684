import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import "@runners/editor/dist/style.css"
import { useGetGuideQuery } from "@/apis/guideApi"
import "../../../../../packages/editor/src/_css/editor.css"

const TipsDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const guideId = Number(id)

  if (isNaN(guideId) || guideId <= 0) {
    return <p className="py-20 text-center text-red-500">잘못된 가이드 ID입니다.</p>
  }

  const {
    data: guide,
    isLoading,
    isError,
  } = useGetGuideQuery(guideId, {
    enabled: !!guideId,
  })

  useEffect(() => {
    const stepNodes = document.querySelectorAll("guide-step-template")
    stepNodes.forEach(node => {
      const stepNumber = node.getAttribute("stepNumber")
      const title = node.getAttribute("title")
      const content = node.getAttribute("content")
      const image = node.getAttribute("image")
      node.innerHTML = `
      <div style="font-size: 14px; font-weight: 400; text-align:center; line-height:20px; color:#9FA0AF; margin-bottom: 24px;">
        ${stepNumber || "스텝 번호"}
      </div>
      <div style="font-size: 28px; font-weight: 700; text-align:center; line-height:36px; color: #424350; margin-bottom: 12px;">
        ${title || "스텝 제목"}
      </div>
      <div style="font-size: 16px; font-weight: 400; text-align:center; line-height:24px; color: #626270; margin-bottom: 32px;">
        ${content || "스텝 내용"}
      </div>
      ${image ? `<img src="${image}" alt="스텝 이미지" style="width: 1080px; max-height:386px" />` : ""}`
    })
  }, [guide])

  useEffect(() => {
    const stepNodes = document.querySelectorAll("guide-step-template")
    stepNodes.forEach(node => {
      const stepNumber = node.getAttribute("stepNumber")
      const title = node.getAttribute("title")
      const content = node.getAttribute("content")
      const image = node.getAttribute("image")
      node.innerHTML = `
      <div style="font-size: 14px; font-weight: 400; text-align:center; line-height:20px; color:#9FA0AF; margin-bottom: 24px;">
        ${stepNumber || "스텝 번호"}
      </div>
      <div style="font-size: 28px; font-weight: 700; text-align:center; line-height:36px; color: #424350; margin-bottom: 12px;">
        ${title || "스텝 제목"}
      </div>
      <div style="font-size: 16px; font-weight: 400; text-align:center; line-height:24px; color: #626270; margin-bottom: 32px;">
        ${content || "스텝 내용"}
      </div>
      ${image ? `<img src="${image}" alt="스텝 이미지" style="width: 1080px; max-height:386px" />` : ""}`
    })
  }, [guide])

  if (isLoading) return <p className="py-20 text-center">가이드를 불러오는 중입니다...</p>
  if (isError || !guide) return <p className="py-20 text-center text-red-600">가이드를 불러올 수 없습니다.</p>

  return (
    <div className="mx-auto px-5 py-20">
      <h1 className="mb-[100px] text-center text-34 font-bold text-[#21222E] mobile:mb-[60px]">{guide?.data.title}</h1>
      <div className="w-[1080px] mobile:w-[335px]">
        {guide.data.content ? (
          <div className="tiptap [&_p]:min-h-4" dangerouslySetInnerHTML={{ __html: guide.data.content }} />
        ) : (
          <p>내용이 없습니다.</p>
        )}
      </div>
    </div>
  )
}

export default TipsDetailPage
