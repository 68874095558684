import { useQuery } from "@tanstack/react-query"
import userApi, { UserQueryKeys } from "@/apis/userApi"
import { getAccessToken } from "@/utils/cookie"

const useMyInfo = () => {
  const token = getAccessToken()

  const { data } = useQuery({
    queryKey: UserQueryKeys.userMe(),
    queryFn: () => userApi.getUserMe(),
    select: response => response.data,
    enabled: !!token,
  })

  if (!token) return undefined

  return data
}

export default useMyInfo
