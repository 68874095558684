import { PropsWithChildren, useEffect, useState } from "react"

import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom"
import mixpanel from "mixpanel-browser"

const GA_TRACKING_ID = import.meta.env.VITE_SENSE_STORE_GOOGLE_ANALYTICS_ID
const MIXPANEL_TOKEN = import.meta.env.VITE_SENSE_STORE_MIXPANEL_TOKEN
const APP_ENV = import.meta.env.VITE_ENV

/**
 * useLocation을 사용해야해서 App에서 사용할 수 없어서 각각 적용중
 * 추후 개선해야하는 부분
 */
const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (GA_TRACKING_ID) {
      ReactGA.initialize(GA_TRACKING_ID)
      setInitialized(true)
    }

    if (MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN, { debug: APP_ENV !== "PROD", track_pageview: "full-url" })
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname })
      ReactGA.send("pageview")
      mixpanel.track_pageview()
    }
  }, [initialized, location])

  return <>{children}</>
}

export default AnalyticsProvider
