import { Button } from "antd"

const NotFound = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-14">
        <div>
          <h2 className="text-center text-[170px] font-light text-primary-900">404</h2>
          <p className="text-center text-28 font-bold text-primary-900">페이지를 찾을 수 없어요</p>
          <div className="h-8"></div>
          <div className="text-center text-16 font-medium text-gray-400">
            요청하신 페이지가 사라졌거나
            <br />
            잘못된 경로를 이용하셨어요.
          </div>
        </div>
        <Button href="/" type="primary" size="large" ghost>
          메인페이지로 가기
        </Button>
      </div>
    </div>
  )
}

export default NotFound
