import { useQuery } from "@tanstack/react-query"
import storeApi, { StoreQueryKeys } from "@/apis/storeApi"
import useMyInfo from "./use-my-info"

const useStoreInfo = () => {
  const myInfo = useMyInfo()

  const storeId = myInfo?.store || 0

  const { data } = useQuery({
    queryKey: StoreQueryKeys.store(storeId),
    queryFn: () => storeApi.getStore({ storeId }),
    enabled: !!storeId,
    select: response => response.data,
  })

  if (!storeId) return null

  return data
}

export default useStoreInfo
