export const toLocale = (value: number | string | undefined): string => {
  // value가 nullish 한 경우 0으로 처리
  if(value == null) {
    return "0"
  }

  const numberValue = typeof value === "string" ? parseFloat(value) : value


  // 숫자가 NaN일 경우 0으로 처리
  if (isNaN(numberValue)) {
    return "0"
  }

  // 숫자를 로케일에 맞게 콤마 포함된 형식으로 변환
  return numberValue.toLocaleString()
}

export const toLocalePrice = (price: string | number | undefined, suffix: string = ""): string => {
  return toLocale(price) + suffix
}