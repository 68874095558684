import dayjs from "dayjs"
import { useGetOrderResolutionQuery } from "../../../order-cancel/_hooks/use-get-query"
import { useCancelReturnContext } from "../../_hooks/use-cancel-return-context"

const OrdererInfo = () => {
  const { isCancel, orderResolutionId } = useCancelReturnContext()

  const { data } = useGetOrderResolutionQuery({ orderResolutionId })

  if (!data) return null

  return (
    <section>
      <div className="flex gap-2 text-14 text-gray-900">
        <p className="shrink-0 basis-[80px] font-bold">주문번호</p>
        <p className="">
          {dayjs(data.ordered_at).format("YYYY-MM-DD")} [{data.order_number}]
        </p>
      </div>
      <Divider />

      <div className="grid grid-cols-2">
        <div className="flex gap-2 text-14 text-gray-900">
          <p className="shrink-0 basis-[80px] font-bold">수취인</p>
          <p className="">{data.receiver_name}</p>
        </div>
        <div className="flex gap-2 text-14 text-gray-900">
          <p className="shrink-0 basis-[80px] font-bold">주문자</p>
          <p className="">{data.orderer_name}</p>
        </div>
      </div>
      <Divider />
      <div className="flex gap-2 text-14 text-gray-900">
        <p className="shrink-0 basis-[80px] font-bold">배송주소</p>
        <p className="">{data.address}</p>
      </div>

      <Divider />

      {!isCancel && (
        <>
          <div className="grid grid-cols-2">
            <div className="flex gap-2 text-14 text-gray-900">
              <p className="shrink-0 basis-[80px] font-bold">주문 접수 일자</p>
              <p className="">{dayjs(data.ordered_at).format("YYYY-MM-DD HH:mm:ss")}</p>
            </div>
            <div className="flex gap-2 text-14 text-gray-900">
              <p className="shrink-0 basis-[80px] font-bold">환불 접수 일자</p>
              <p className="">{dayjs(data.created).format("YYYY-MM-DD HH:mm:ss")}</p>
            </div>
          </div>

          <Divider />
        </>
      )}
    </section>
  )
}

export default OrdererInfo

const Divider = () => <div className="my-[14px] h-px w-full bg-gray-300" />
