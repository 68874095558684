import { StoreLogoIcon } from "@/assets/icons"

const Logo = ({ isBeta = false }: { isBeta?: boolean }) => {
  return (
    <div className="flex gap-1.5 text-primary">
      <StoreLogoIcon className="h-8 md:h-9" />

      {isBeta && (
        <div className="flex h-6 items-center justify-center rounded bg-primary-100 px-1.5 py-0.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="11" viewBox="0 0 26 11" fill="none">
            <path
              d="M20.8916 10.8581C20.2706 10.8581 19.7787 10.636 19.4161 10.1917C19.0534 9.7475 18.8721 9.12193 18.8721 8.31504C18.8721 7.38576 19.0307 6.51994 19.3481 5.71758C19.6699 4.91069 20.1119 4.27606 20.674 3.81368C21.2361 3.3513 21.8572 3.12012 22.5372 3.12012C22.9588 3.12012 23.3282 3.23118 23.6455 3.4533C23.9674 3.67542 24.2235 3.995 24.4139 4.41205H24.4887L24.9307 3.25611H25.8623L24.2779 10.7221H23.3056L23.4823 9.34179H23.4279C22.6437 10.3527 21.7983 10.8581 20.8916 10.8581ZM21.2996 9.84496C21.7257 9.84496 22.1518 9.64097 22.578 9.23299C23.0086 8.82501 23.3532 8.29464 23.6115 7.64188C23.8699 6.98458 23.9991 6.31595 23.9991 5.63598C23.9991 5.19174 23.8767 4.83363 23.6319 4.56164C23.3872 4.28512 23.063 4.14686 22.6596 4.14686C22.2017 4.14686 21.7756 4.33952 21.3812 4.72483C20.9913 5.10561 20.6854 5.62012 20.4632 6.26835C20.2411 6.91658 20.13 7.60788 20.13 8.34224C20.13 8.84541 20.232 9.22166 20.436 9.47098C20.64 9.7203 20.9279 9.84496 21.2996 9.84496Z"
              fill="currentColor"
            />
            <path
              d="M16.6917 9.85851C16.9728 9.85851 17.3014 9.79731 17.6777 9.67492V10.6133C17.5508 10.6767 17.3672 10.7334 17.1269 10.7833C16.8912 10.8331 16.6849 10.8581 16.5081 10.8581C15.9279 10.8581 15.4859 10.7221 15.1822 10.4501C14.8784 10.1781 14.7266 9.77238 14.7266 9.23294C14.7266 8.94282 14.7674 8.61191 14.849 8.2402L15.6989 4.18761H14.543L14.6518 3.63684L15.9097 3.10647L16.7733 1.55615H17.4941L17.1201 3.25606H18.9969L18.8065 4.18761H16.9365L16.0729 8.26059C15.9913 8.62778 15.9505 8.90429 15.9505 9.09015C15.9505 9.30774 16.0072 9.49133 16.1205 9.64092C16.2339 9.78598 16.4243 9.85851 16.6917 9.85851Z"
              fill="currentColor"
            />
            <path
              d="M11.6602 4.13326C11.1933 4.13326 10.7536 4.35312 10.3411 4.79283C9.92853 5.228 9.62934 5.79691 9.44348 6.49954H9.54548C10.5337 6.49954 11.2998 6.36808 11.8438 6.10516C12.3878 5.84224 12.6598 5.45919 12.6598 4.95602C12.6598 4.71577 12.5782 4.51858 12.415 4.36445C12.2518 4.21033 12.0002 4.13326 11.6602 4.13326ZM10.7014 10.8581C9.85827 10.8581 9.19642 10.6111 8.7159 10.1169C8.23538 9.6183 7.99512 8.92927 7.99512 8.04985C7.99512 7.19763 8.16058 6.38394 8.4915 5.60878C8.82696 4.83363 9.27802 4.22619 9.84467 3.78648C10.4113 3.34224 11.0392 3.12012 11.7282 3.12012C12.4354 3.12012 12.9681 3.27424 13.3262 3.58249C13.6843 3.88621 13.8634 4.31232 13.8634 4.86082C13.8634 5.68585 13.4826 6.33181 12.721 6.79872C11.9594 7.2611 10.885 7.49228 9.49788 7.49228H9.26668L9.23948 8.00906C9.23948 8.58023 9.37548 9.029 9.64747 9.35538C9.924 9.68177 10.3479 9.84496 10.919 9.84496C11.2681 9.84496 11.6013 9.79509 11.9186 9.69537C12.2405 9.59564 12.585 9.45511 12.9522 9.27379V10.3209C12.5034 10.5295 12.1113 10.67 11.7758 10.7425C11.4404 10.8196 11.0822 10.8581 10.7014 10.8581Z"
              fill="currentColor"
            />
            <path
              d="M3.35911 10.8581C2.92846 10.8581 2.5454 10.747 2.20994 10.5249C1.87448 10.3028 1.62062 9.98772 1.44836 9.57974H1.38036L0.924775 10.7221H0L2.24394 0.141846H3.46791L2.93752 2.64411C2.85592 3.04756 2.75846 3.46687 2.64513 3.90205C2.5318 4.33722 2.45927 4.58654 2.42754 4.65001H2.48193C2.94432 4.0879 3.36818 3.69579 3.7535 3.47367C4.14336 3.24701 4.54001 3.13369 4.94347 3.13369C5.58718 3.13369 6.0881 3.35581 6.44623 3.80005C6.80889 4.23976 6.99021 4.86306 6.99021 5.66995C6.99021 6.6083 6.83608 7.48092 6.52783 8.28781C6.21957 9.09017 5.78665 9.72027 5.22906 10.1781C4.67601 10.6314 4.05269 10.8581 3.35911 10.8581ZM4.55588 4.14683C4.12522 4.14683 3.69004 4.36215 3.25031 4.7928C2.81512 5.21891 2.4706 5.75608 2.21674 6.40431C1.96741 7.04801 1.84275 7.69398 1.84275 8.34221C1.84275 8.81365 1.96288 9.1831 2.20314 9.45055C2.44793 9.71347 2.80153 9.84493 3.26391 9.84493C3.70817 9.84493 4.12069 9.65907 4.50148 9.28736C4.88227 8.91564 5.18373 8.40114 5.40586 7.74384C5.62798 7.08201 5.73905 6.38618 5.73905 5.65635C5.73905 4.65001 5.34466 4.14683 4.55588 4.14683Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default Logo
