import { ChangeEvent } from "react"
import useMyInfo from "@/hooks/use-my-info"
import { MutationOptions } from "@/types/mutation.type"
import { useStoreUploadImagesMutation } from "./use-mutation"

type UploadOptions = {
  maximum?: number
  maxFileSize?: number
  type: "DETAIL" | "ADDITION" | "THUMBNAIL"
}

const defaultOptions = {
  maximum: 10, // 10개
  maxFileSize: 10 * 1024 * 1024, // 10MB
  type: "THUMBNAIL",
}

export const useUploadImage = (mutationOptions: MutationOptions, uploadOptions: UploadOptions) => {
  const store = useMyInfo()
  const storeId = store?.store || 0

  const imagesUpload = useStoreUploadImagesMutation({
    ...mutationOptions,
  })

  const { maximum, maxFileSize, type } = {
    ...defaultOptions,
    ...uploadOptions,
  }

  const handleChangeFileMultiple = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) return

    if (maximum && files.length > maximum) {
      alert(`이미지는 ${maximum}개까지만 등록 가능합니다.`)
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        alert(`이미지는 ${maxFileSize / 1024 / 1024}MB 이하만 등록 가능합니다.\n초과한 파일: ${files[i].name}`)
        return
      }
    }

    const formData = new FormData()
    formData.append("type", type)

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i])
    }
    imagesUpload.mutate({ storeId, payload: formData })
  }

  return { handleChangeFileMultiple }
}
