import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { CloseCircleFilled } from "@ant-design/icons"
import { Select } from "antd"
import SearchInput from "@/pages/dashboard/components/search-input"

const options = [
  { value: "receiver_name", label: "수취인명" },
  { value: "orderer_name", label: "구매자명" },
  { value: "orderer_phone", label: "구매자 연락처" },
  { value: "order_number", label: "주문번호" },
  { value: "product_number", label: "상품번호" },
  { value: "tracking_number", label: "송장번호" },
]

const SearchFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const key = options.find(option => searchParams.has(option.value))?.value

  const [type, setType] = useState(key)
  const [search, setSearch] = useState((key && searchParams.get(key)) ?? "")

  const handleTypeChange = (value: string) => {
    setType(value)
  }

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.trim()
    setSearch(value)
  }

  const handleResetClick = () => {
    setSearch("")
    if (type) searchParams.delete(type)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!type) return

    if (search) {
      searchParams.set(type, search)
    }

    const keys = [...searchParams.keys()]
    keys.forEach(key => {
      if (options.some(option => option.value === key) && key !== type) {
        searchParams.delete(key)
      }
    })

    setSearchParams(searchParams)
  }, [type, search])

  return (
    <div className="flex gap-3">
      <Select className="w-[140px]" placeholder="검색필터" options={options} value={type} onChange={handleTypeChange} />
      <SearchInput
        placeholder="검색어 입력 (2글자 이상)"
        className="w-[365px]"
        onPressEnter={handleSearch}
        defaultValue={search}
        allowClear={{
          clearIcon: <CloseCircleFilled onClick={handleResetClick} />,
        }}
      />
    </div>
  )
}

export default SearchFilter
