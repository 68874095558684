import { OrderStatus } from "@runners/lib"
import cn from "@/utils/cn"
import { useGetOrderResolutionQuery } from "../../../order-cancel/_hooks/use-get-query"
import { useCancelReturnContext } from "../../_hooks/use-cancel-return-context"

const ReturnExchangeChoice = () => {
  const { isCancel, orderResolutionId } = useCancelReturnContext()
  const { data } = useGetOrderResolutionQuery({ orderResolutionId })

  if (isCancel) return null
  if (!data) return null

  const { order_resolution_choice, order_product_group } = data

  const status = order_product_group.status

  if (status === OrderStatus.RETURN_EXCHANGE_ONGOING) {
    return <>승인한 교환/반품건입니다.</>
  }

  const isCustomer = order_resolution_choice.liability === "CUSTOMER"

  const title = isCustomer ? "구매자 귀책 사유에 의한 환불" : "판매자 귀책 사유에 의한 환불"
  const label = isCustomer
    ? "상기 취소 사유는 주문자 귀책사유에 해당하므로 셀러님께서는 상품 회수 운송비 입금을 확인 후 회수 진행해 주시기 바랍니다."
    : "상기 취소 사유는 판매자 귀책사유에 해당하므로 셀러님께서는 상품 회수 운송비를 부담해 주시고 상품을 회수 진행해 주시기 바랍니다."

  return (
    <div className="flex flex-col gap-2">
      <p className="text-16 font-bold text-gray-900">환불 귀책 정책</p>
      <div
        className={cn("flex items-center justify-between rounded-1 px-3 py-2 text-14 font-bold", {
          "bg-error-100 text-error-500": !isCustomer,
          "bg-[rgba(62,151,255,0.1)] text-eventColor-travel": isCustomer,
        })}
      >
        <p>{title}</p>

        <p className="font-normal">{order_resolution_choice.title}</p>
      </div>

      <p className="text-14 text-gray-900">{label}</p>
    </div>
  )
}

export default ReturnExchangeChoice
