import { Dispatch, SetStateAction, useState } from "react"
import { Button, useTabContext } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import { StoreQueryKeys } from "@/apis/storeApi"
import useMyInfo from "@/hooks/use-my-info"
import useToast from "@/hooks/use-toast"
import { TAB_LIST } from "."
import { useDeleteStoreImagesMutation } from "../../hooks/use-mutation"
import { useUploadImage } from "../../hooks/use-upload-image"

interface UploadSelectedButtonsProps {
  selectedRows: any[]
  setSelectedRows: Dispatch<SetStateAction<any[]>>
}

const UploadSelectedButtons = (props: UploadSelectedButtonsProps) => {
  const { selectedRows, setSelectedRows } = props

  const qc = useQueryClient()
  const toast = useToast()
  const myInfo = useMyInfo()
  const storeId = myInfo?.store || 0

  const { current } = useTabContext<(typeof TAB_LIST)[number]>()

  /**
   * delete mutation
   */
  const deleteMutation = useDeleteStoreImagesMutation({
    onSuccess: () => {
      toast.success("삭제되었습니다.")
      setSelectedRows([])
      qc.invalidateQueries({
        queryKey: StoreQueryKeys.storeImage.all({ storeId: storeId }),
      })
    },
    onError: () => {
      toast.error("삭제에 실패했습니다.")
    },
  })

  const [isUploading, setIsUploading] = useState(false)
  const { handleChangeFileMultiple } = useUploadImage(
    {
      onSuccess: () => {
        toast.success("업로드 되었습니다.")
        qc.invalidateQueries({
          queryKey: StoreQueryKeys.storeImage.all({ storeId: storeId }),
        })
        setIsUploading(false)
      },
      onError: () => {
        toast.error("업로드에 실패했습니다.")
        setIsUploading(false)
      },
    },
    { maximum: 500, maxFileSize: 20 * 1024 * 1024, type: current.value },
  )

  return (
    <div className="flex items-center justify-between">
      {selectedRows.length > 0 ? (
        <div className="flex items-center gap-2">
          <Button
            color="dark"
            size="40"
            className="px-5"
            onClick={() => {
              const text = selectedRows.map(v => v.url).join("\n")
              navigator.clipboard.writeText(text)
              setSelectedRows([])
              toast.success(`복사되었습니다.`)
            }}
          >
            URL 복사
          </Button>
          <Button
            size="40"
            className="bg-error-500 px-5"
            onClick={() => {
              deleteMutation.mutate({
                storeId,
                image_ids: selectedRows.map(v => v.id),
              })
            }}
          >
            삭제
          </Button>
        </div>
      ) : (
        <div />
      )}

      <label
        htmlFor="image_modal_upload"
        className="cursor-pointer rounded-1 bg-primary px-5 py-2.5 text-14 font-bold text-white"
      >
        <input
          name={"image_modal_upload"}
          id="image_modal_upload"
          type="file"
          accept="image/*"
          multiple
          className="hidden"
          onChange={async e => {
            setIsUploading(true)
            await handleChangeFileMultiple(e)
          }}
        />
        이미지 추가
      </label>
    </div>
  )
}

export default UploadSelectedButtons
