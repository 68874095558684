import React from "react"
import { cn } from "@runners/lib/utils"
import { cva } from "class-variance-authority"

const textareaVariants = cva(
  "resize-none rounded-1 border border-gray-300 px-4 py-1 text-gray-900 transition-colors placeholder:text-gray-500 focus:outline focus:outline-state",
  {
    variants: {
      disabled: {
        true: "cursor-not-allowed bg-gray-100 text-gray-400",
      },
      isError: {
        true: "border-error-500",
        false: "",
      },
    },
  },
)

type TextareaPropsBase = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "size">

export interface TextareaProps extends TextareaPropsBase {
  isError?: boolean
  suffix?: React.ReactNode
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, disabled, isError, suffix, ...props }: TextareaProps, ref) => {
    if (suffix) {
      return (
        <label className="relative">
          <textarea
            ref={ref}
            className={cn(
              textareaVariants({
                disabled,
                isError,
              }),
              className,
            )}
            disabled={disabled}
            {...props}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-4">{suffix}</div>
        </label>
      )
    }

    return (
      <textarea
        ref={ref}
        className={cn(
          textareaVariants({
            disabled,
            isError,
          }),
          className,
        )}
        disabled={disabled}
        {...props}
      />
    )
  },
)

Textarea.displayName = "Textarea"

export default Textarea
