"use client"

import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { Dayjs } from "dayjs"
import { createDynamicContext } from "@/hooks/create-dynamic-context"

export type DateRangeType = "week" | "month" | "threeMonth" | "custom"

export type CheckboxState = {
  all: boolean
  waiting: boolean
  failed: boolean
  complete: boolean
}

interface TableFilterContextProps {
  dates: [Dayjs | null, Dayjs | null] | null
  setDates: Dispatch<SetStateAction<[Dayjs | null, Dayjs | null] | null>>

  rangeType: DateRangeType
  setRangeType: Dispatch<SetStateAction<DateRangeType>>

  checkboxState: CheckboxState
  setCheckboxState: Dispatch<SetStateAction<CheckboxState>>
}

const { ContextProvider, useContext } = createDynamicContext<TableFilterContextProps>()

export const useTableFilterContext = useContext
export const TableFilterContextProvider = (props: TableFilterContextProps & PropsWithChildren) => {
  const { checkboxState, dates, rangeType, setCheckboxState, setDates, setRangeType, children } = props

  return (
    <ContextProvider
      checkboxState={checkboxState}
      dates={dates}
      rangeType={rangeType}
      setCheckboxState={setCheckboxState}
      setDates={setDates}
      setRangeType={setRangeType}
    >
      {children}
    </ContextProvider>
  )
}
