const LabelWithItem = ({
  label,
  required,
  children,
}: React.PropsWithChildren<{ label: React.ReactNode; required?: boolean }>) => (
  <div className="flex flex-col">
    <label className="py-1.5 text-14 font-bold text-gray-800">
      {label}
      {required && <span className="text-red-500">*</span>}
    </label>
    {children}
  </div>
)

export default LabelWithItem
