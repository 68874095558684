import { Toaster } from "react-hot-toast"
import { RouterProvider } from "react-router-dom"
import { StyleProvider } from "@ant-design/cssinjs"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ConfigProvider, ThemeConfig } from "antd"
import locale from "antd/locale/ko_KR"
import dayjs from "dayjs"
import "dayjs/locale/ko"
import ModalProvider from "@/components/modal/modal-provider"
import router from "./router"

dayjs.locale("ko")

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#FF8A00",
    colorError: "#F23B3B",
    borderRadius: 4,
  },
}

const queryClient = new QueryClient({})
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StyleProvider>
        <ConfigProvider theme={theme} locale={locale}>
          <Toaster containerStyle={{ top: 100 }} />
          <ModalProvider />
          <RouterProvider router={router} />
        </ConfigProvider>
      </StyleProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
