import { useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Dropdown, MenuProps } from "antd"
import { CaretUpIcon } from "@/assets/icons"
import useMyInfo from "@/hooks/use-my-info"
import { removeAccessToken, removeRefreshToken } from "@/utils/cookie"

const UserInfoDropDown = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const myInfo = useMyInfo()

  const menu: MenuProps = {
    items: [
      { label: "내 정보", key: "my-info" },
      { label: "로그아웃", key: "logout" },
    ],
    onClick: ({ key }) => {
      switch (key) {
        case "my-info":
          navigate("/dashboard/my-info")
          break
        case "logout":
          removeAccessToken()
          removeRefreshToken()
          queryClient.clear()
          navigate("/sign-in", { replace: true })
          break
      }
    },
  }
  return (
    <Dropdown menu={menu}>
      <Button className="inline-flex items-center gap-2">
        <span>{myInfo?.username}님</span>
        <CaretUpIcon className="size-5 rotate-180" />
      </Button>
    </Dropdown>
  )
}

export default UserInfoDropDown
