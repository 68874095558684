import * as Sentry from "@sentry/react"
import axios from "axios"
import qs from "qs"
import { getAccessToken, removeAccessToken } from "@/utils/cookie"

export const baseURL = `${import.meta.env.VITE_API_URL}/${import.meta.env.VITE_API_VERSION}`

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
}

const api = axios.create({
  baseURL,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: "comma" })
  },
})

api.interceptors.request.use(config => {
  const accessToken = getAccessToken()
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error) {
      if (error.response.status === 401) {
        removeAccessToken()
      }
    }

    Sentry.withScope(scope => {
      scope.setTag("http_method", error.config.method)
      scope.setTag("http_url", error.config.url)
      scope.setExtra("request_headers", error.config.headers)
      scope.setExtra("request_data", error.config.data)

      if (error.response) {
        scope.setTag("response_status", error.response.status)
        scope.setExtra("response_headers", error.response.headers)
        scope.setExtra("response_data", error.response.data)
      }

      Sentry.captureException(error)
    })

    return Promise.reject(error)
  },
)

export default api
