import { Suspense, lazy } from "react"
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom"

import DashboardLayout from "@/components/layout/dashboard-layout"
import PublicLayout from "@/components/layout/public-layout/public-layout"

import Loading from "./components/loading"
import AnalyticsProvider from "./lib/analytics/AnalyticsProvider"
import BulkProductsListPage from "./pages/dashboard/products/bulk-products/list"
import ShippingGroupPolicyPage from "./pages/dashboard/products/shipping-group-policy/list"

import OrderCancelPage from "./pages/dashboard/sales/order-cancel/list"
import ReturnExchangePage from "./pages/dashboard/sales/return-exchange/list"
import SettlementListPage from "./pages/dashboard/settlement/list"
import FaqPage from "./pages/faq"
import NotFound from "./pages/not-found"
import NoticePage from "./pages/notice"
import OnboardingPage from "./pages/onboarding"
import TipsPage from "./pages/tips"
import TipsDetailPage from "./pages/tips/detail"

const LandingPage = lazy(() => import("@/pages/landing"))
const SignUpPage = lazy(() => import("@/pages/sign-up"))
const SignInPage = lazy(() => import("@/pages/sign-in"))
const OauthKakaoPage = lazy(() => import("@/pages/oauth/kakao"))
const SellerVerifyPage = lazy(() => import("@/pages/seller-verify"))

const MyInfoPage = lazy(() => import("@/pages/dashboard/my-info"))

const BizInfoPage = lazy(() => import("@/pages/dashboard/management/biz-info"))
const AdminPage = lazy(() => import("@/pages/dashboard/management/admin"))

const ProductsListPage = lazy(() => import("@/pages/dashboard/products/product/list"))
const ProductDetailPage = lazy(() => import("@/pages/dashboard/products/product/detail"))
const CommonPolicyPage = lazy(() => import("@/pages/dashboard/products/common-policy/list"))
const CommonPolicyDetailPage = lazy(() => import("@/pages/dashboard/products/common-policy/detail"))
const ShippingPolicyPage = lazy(() => import("@/pages/dashboard/products/shipping-policy/list"))

const OrderListPage = lazy(() => import("@/pages/dashboard/sales/orders/list"))
const OrderDetailPage = lazy(() => import("@/pages/dashboard/sales/orders/detail"))
const DeliveryListPage = lazy(() => import("@/pages/dashboard/sales/delivery/list"))

const router = createBrowserRouter([
  {
    path: "/",

    element: <PublicLayout />,
    children: [
      { path: "", element: <LandingPage /> },
      { path: "sign-up/:step?", element: <SignUpPage /> },
      { path: "sign-in", element: <SignInPage /> },
      { path: "oauth/kakao", element: <OauthKakaoPage /> },
      { path: "seller-verify", element: <SellerVerifyPage /> },
      { path: "onboarding", element: <OnboardingPage /> },
      {
        path: "tips",
        children: [
          { path: "", element: <TipsPage /> },
          { path: ":id", element: <TipsDetailPage /> },
        ],
      },
      { path: "faq", element: <FaqPage /> },
      { path: "notice", element: <NoticePage /> },
      { path: "404", element: <NotFound /> },
    ],
  },

  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      { path: "", element: <Navigate to="my-info" replace /> },
      { path: "my-info", element: <MyInfoPage /> },
      {
        path: "management",
        children: [
          { path: "", element: <Navigate to="biz-info" replace /> },
          { path: "biz-info", element: <BizInfoPage /> },
          { path: "admin", element: <AdminPage /> },
        ],
      },
      {
        path: "bulk-products",
        children: [
          { path: "", element: <Navigate to="list" replace /> },
          { path: "list", element: <BulkProductsListPage /> },
        ],
      },
      {
        path: "products",
        children: [
          { path: "", element: <Navigate to="list" replace /> },
          { path: "list", element: <ProductsListPage /> },
          { path: "common-info", element: <CommonPolicyPage /> },
          { path: "shipping-info", element: <ShippingPolicyPage /> },
          { path: "shipping-group-info", element: <ShippingGroupPolicyPage /> },
        ],
      },
      {
        path: "sales",
        children: [
          { path: "", element: <Navigate to="orders/list" replace /> },
          { path: "orders/list", element: <OrderListPage /> },
          { path: "orders/:orderId", element: <OrderDetailPage /> },
          { path: "delivery/list", element: <DeliveryListPage /> },
          { path: "order-cancel/list", element: <OrderCancelPage /> },
          { path: "return-exchange/list", element: <ReturnExchangePage /> },
        ],
      },
      {
        path: "settlement",
        children: [
          { path: "", element: <Navigate to="list" replace /> },
          { path: "list", element: <SettlementListPage /> },
        ],
      },
    ],
  },
  {
    path: "/dashboard/products",
    element: (
      <AnalyticsProvider>
        <Suspense fallback={<Loading className="flex items-center justify-center size-screen" />}>
          <Outlet />
        </Suspense>
      </AnalyticsProvider>
    ),
    children: [
      { path: ":productId", element: <ProductDetailPage /> },
      { path: "common-info/:commonInfoId", element: <CommonPolicyDetailPage /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
])

export default router
