import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Transition } from "react-transition-group"
import { useQueryClient } from "@tanstack/react-query"
import { UserQueryKeys } from "@/apis/userApi"
import { CaretUpIcon } from "@/assets/icons"
import LinkButton from "@/components/link-button"
import useMyInfo from "@/hooks/use-my-info"
import cn from "@/utils/cn"
import { removeAccessToken, removeRefreshToken } from "@/utils/cookie"

export const MENU_TAB = [
  {
    name: "입점 가이드",
    link: "/onboarding",
  },
  {
    name: "판매활용 Tip",
    link: "/tips",
  },
  {
    name: "FAQ",
    link: "/faq",
  },
  {
    name: "공지사항",
    link: "/notice",
  },
]

const MobileSideMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const close = () => setIsOpen(false)

  const user = useMyInfo()

  const { pathname } = useLocation()
  useEffect(() => {
    close()
  }, [pathname])

  /**
   * 사이드바가 활성화될때 body의 paddingRight 값을 스크롤의 너비만큼 적용시킵니다.
   */
  useEffect(() => {
    if (!isOpen) return

    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.style.paddingRight = `${scrollBarWidth}px`
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.paddingRight = "0px"
      document.body.style.overflow = ""
    }
  }, [isOpen])

  const queryClient = useQueryClient()
  const handleClickLogout = () => {
    removeAccessToken()
    removeRefreshToken()
    queryClient.resetQueries({ queryKey: UserQueryKeys.userMe() })
    queryClient.clear()
  }

  return (
    <>
      <div
        className="cursor-pointer p-2.5"
        onClick={e => {
          e.stopPropagation()
          setIsOpen(prev => !prev)
        }}
      >
        <div className="relative size-5 [&>span]:absolute [&>span]:h-0.5 [&>span]:w-5 [&>span]:rounded-2 [&>span]:bg-gray-800 [&>span]:transition-all [&>span]:duration-300 [&>span]:ease-in-out">
          <span className={cn("top-1", { "rotate-45 top-2.5": isOpen })} />
          <span className={cn("top-2.5", { "w-0 opacity-0": isOpen })} />
          <span className={cn("top-4", { "-rotate-45 top-2.5": isOpen })} />
        </div>
      </div>

      <Transition in={isOpen} timeout={300} unmountOnExit>
        {state => (
          <div
            className={cn(
              "fixed bottom-0 z-30 -ml-5 mt-[60px] h-[calc(100vh-60px)] w-screen bg-white transition-all duration-300 ease-in-out",
              {
                "translate-x-full": state === "exited" || state === "exiting",
                "translate-x-0": state === "entering" || state === "entered",
              },
            )}
          >
            <div className="flex flex-col px-5 py-4">
              <LinkButton
                className="w-full"
                href={"/sign-in"}
                color="primary"
                outline
                size="56"
                onClick={() => {
                  close()
                  if (user) {
                    handleClickLogout()
                  }
                }}
                data-tracking-name="모바일_사이드바_로그인"
              >
                {user ? "로그아웃" : "로그인"}
              </LinkButton>

              <div className="relative mb-5 mt-7 h-px w-full bg-gray-300">
                {!user && (
                  <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap bg-white px-2 text-12 text-gray-600">
                    아직 입점을 하지 않으셨다면?
                  </p>
                )}
              </div>

              <LinkButton
                className="w-full"
                href={user ? "/dashboard" : "/sign-in"}
                color="primary"
                size="56"
                onClick={close}
                data-tracking-name="모바일_사이드바_입점하기"
              >
                {user ? "스토어 관리" : "지금 입점하기"}
              </LinkButton>
            </div>

            <div className="h-3 w-full bg-gray-300" />

            <div className="flex flex-col gap-2 p-2">
              {MENU_TAB.map(v => {
                return (
                  <Link to={v.link} key={v.name} onClick={close} data-tracking-name={`모바일_사이드바_메뉴_${v.name}`}>
                    <div className="group flex items-center justify-between px-3 py-2 ">
                      <p className="text-16 text-gray-700">{v.name}</p>
                      <CaretUpIcon className="size-4 rotate-90 text-gray-400 group-hover:text-gray-700" />
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        )}
      </Transition>
    </>
  )
}

export default MobileSideMenu
