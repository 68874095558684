import api from "."
import { DjangoResponse } from "./storeApi"

export const UserQueryKeys = {
  all: () => ["user"] as const,
  user: (id: string) => [...UserQueryKeys.all(), id] as const,
  userMe: () => UserQueryKeys.user("me"),
}

export type PostUserSignupPayload = {
  email: string
  password: string
  username: string
}

type PostUserLoginPayload = {
  email: string
  password: string
}

type PostUserPhoneSendPayload = {
  phone: string
}

type PostUserPhoneAuthPayload = {
  phone: string
  code: number
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export type PostUserExtraInfoPayload = {
  phone: string
  birthday: string
  gender: Gender
  code: number
}

type PostKakaoOauthPayload = {
  code: string
  redirect_uri: string
}

export interface BusinessUserMeDto {
  birthday: string
  email: string
  gender: Gender
  id: number
  is_extra_info: boolean
  phone: string
  profile_image_url: string | null
  store?: number
  username: string
}

const userApi = {
  getUserMe: async () => {
    const { data } = await api.get<DjangoResponse<BusinessUserMeDto>>("/business/user/me")
    return data
  },

  postUserLogin: async (payload: PostUserLoginPayload) => {
    const { data } = await api.post("/user/login", payload)
    return data
  },

  postUserSignup: async (payload: PostUserSignupPayload) => {
    const { data } = await api.post("/user/signup", payload)
    return data
  },

  postKakaoOauth: async (payload: PostKakaoOauthPayload) => {
    const { data } = await api.post("/kakao/oauth", payload)
    return data
  },

  postUserPhoneSend: async (payload: PostUserPhoneSendPayload) => {
    const { data } = await api.post("/user/phone/send", payload)
    return data
  },

  postUserPhoneAuth: async (payload: PostUserPhoneAuthPayload) => {
    const { data } = await api.post("/user/phone/auth", payload)
    return data
  },

  postUserExtraInfo: async (payload: PostUserExtraInfoPayload) => {
    const { data } = await api.post("/user/extra-info", payload)
    return data
  },
}

export default userApi
