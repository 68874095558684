import mixpanel from "mixpanel-browser"

import { AnalysisHelperParams } from "./analytics.type"

/**
 * 믹스패널 이벤트 헬퍼
 * @param event
 * @param params
 */
export const MXHelper = (event: string, params: AnalysisHelperParams) => {
  const eventParams = {
    ...params,
  }
  mixpanel.track(event, eventParams)
}
