import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Button } from "antd"
import storeApi, { CombineShippingPolicyPayload, StoreQueryKeys } from "@/apis/storeApi"
import Loading from "@/components/loading"
import ModalContainer from "@/components/modal/modal-container"
import useMyInfo from "@/hooks/use-my-info"
import useToast from "@/hooks/use-toast"
import BundleShippingForm from "./bundle-shipping-form"
import { usePatchCombineShippingPolicy, usePostCombineShippingPolicy } from "../hooks/use-mutation"

interface BundleShippingModalProps {
  close: () => void
  id?: number
}

export type FormType = {
  title: string
  combine_fee_type: "MIN" | "MAX"
  is_extra_fee: boolean
  jeju_extra_fee: string
  etc_extra_fee: string
}

const BundleShippingModal = ({ close, id }: BundleShippingModalProps) => {
  const myInfo = useMyInfo()
  const toast = useToast()

  const successCallback = () => {
    close()
    queryClient.invalidateQueries({
      queryKey: StoreQueryKeys.combineShippingPolicyList({ storeId: myInfo?.store || 0 }),
    })
  }

  const queryClient = useQueryClient()
  const createMutation = usePostCombineShippingPolicy({
    onSuccess: () => {
      successCallback()
      toast.success("묶음 정책이 생성되었습니다.")
    },
  })
  const updateMutation = usePatchCombineShippingPolicy({
    onSuccess: () => {
      successCallback()
      toast.success("묶음 정책이 변경되었습니다.")
    },
  })

  /**
   * react-hook-form
   */
  const methods = useForm<FormType>({
    mode: "onTouched",
    defaultValues: {},
  })

  /** ------------------------------------------------------------------------------
   * 
   * 수정을 위한 state
   * 
   ------------------------------------------------------------------------------ */
  const { data: combineShippingPolicy, isLoading } = useQuery({
    queryKey: StoreQueryKeys.combineShippingPolicy({ storeId: myInfo?.store || 0, combineShippingPolicyId: id }),
    queryFn: () => storeApi.getCombineShippingPolicy({ combineShippingPolicyId: id }),
    enabled: !!id,
    select: response => response.data,
  })

  useEffect(() => {
    if (!combineShippingPolicy) return

    methods.reset({
      title: combineShippingPolicy.title,
      combine_fee_type: combineShippingPolicy.combine_fee_type,
      is_extra_fee: combineShippingPolicy.is_extra_fee,
      etc_extra_fee: combineShippingPolicy.etc_extra_fee || "",
      jeju_extra_fee: combineShippingPolicy.jeju_extra_fee || "",
    })
  }, [combineShippingPolicy])

  /**
   * submit
   */
  const onSubmit: SubmitHandler<FormType> = async data => {
    const { combine_fee_type, etc_extra_fee, is_extra_fee, jeju_extra_fee, title } = data

    const payload: Partial<CombineShippingPolicyPayload> = {
      is_used: true,
      title,
      combine_fee_type,
      is_extra_fee,
      ...(is_extra_fee ? { jeju_extra_fee: Number(jeju_extra_fee) } : {}),
      ...(is_extra_fee ? { etc_extra_fee: Number(etc_extra_fee) } : {}),
    }

    //update
    if (id) {
      updateMutation.mutate({
        combineShippingPolicyId: id,
        payload: payload,
      })
      // create
    } else {
      createMutation.mutate({
        storeId: myInfo?.store || 0,
        payload: payload,
      })
    }
  }

  return (
    <ModalContainer title="배송비 묶음 설정하기" className="w-[680px]" close={close}>
      <div className="border-y border-y-gray-300 p-5">
        {isLoading ? (
          <Loading className="flex w-full items-center justify-center" />
        ) : (
          <FormProvider {...methods}>
            <BundleShippingForm />
          </FormProvider>
        )}
      </div>
      <footer className="flex items-center justify-end gap-2 px-5 py-4">
        <Button size="large" onClick={close}>
          취소
        </Button>
        <Button size="large" type="primary" onClick={methods.handleSubmit(onSubmit)}>
          확인
        </Button>
      </footer>
    </ModalContainer>
  )
}

export default BundleShippingModal
