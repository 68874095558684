"use client"

import { useEffect, useRef, useState } from "react"
import { Button } from "antd"

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: {
      triggerFunction: (showCollectorDialog: () => void) => void
    }
  }
}

const IssueCollector = () => {
  const issueCollectorRef = useRef<() => void>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!mounted || issueCollectorRef.current) return

    if (typeof window !== "undefined") {
      const script = document.createElement("script")
      script.src =
        "https://runners-team.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/278rlr/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=ko-KR&collectorId=8a16583e"
      script.async = true
      script.onload = () => {
        window.ATL_JQ_PAGE_PROPS = {
          triggerFunction(showCollectorDialog) {
            issueCollectorRef.current = showCollectorDialog
          },
        }
      }

      document.body.appendChild(script)
    }
  }, [mounted])

  return (
    <Button
      type="link"
      onClick={() => {
        const showCollectorDialog = issueCollectorRef.current

        if (showCollectorDialog) {
          showCollectorDialog()
        }
      }}
    >
      버그 및 이슈 제보하기
    </Button>
  )
}

export default IssueCollector
