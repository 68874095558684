const ImageStorageGuide = () => {
  return (
    <ul className="li-marker-style list-inside list-disc text-14 text-gray-700">
      <li>이미지는 한번에 최대 500개, 500MB (한 장당 20MB)까지 업로드할 수 있습니다.</li>
      <li>JPG, JPEG, GIF, PNG, BMP, TIF, TIFF, SVG 형식만 지원됩니다.</li>
      <li>저작권 등 다른 사람의 권리를 침해하는 이미지는 관련 법률에 의해 제재 받을 수 있습니다.</li>
      <li className="font-bold">
        (※ 이미지 추가 후 업로드창에 보여지는 이미지 URL을 엑셀파일에 기재해주시기 바랍니다.)
      </li>
    </ul>
  )
}

export default ImageStorageGuide
