import { useEffect, useState } from "react"

type UseScrollDepthProps = {
  thresholds: number[]
  callback: (threshold: number) => void
}

const useScrollDepth = ({ thresholds, callback }: UseScrollDepthProps) => {
  const [triggeredThresholds, setTriggeredThresholds] = useState<number[]>([])

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
      const scrollPosition = window.scrollY
      const scrollPercentage = (scrollPosition / scrollHeight) * 100

      thresholds.forEach(threshold => {
        if (scrollPercentage >= threshold && !triggeredThresholds.includes(threshold)) {
          callback(threshold)
          setTriggeredThresholds(prev => [...prev, threshold])
        }
      })
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [thresholds, callback, triggeredThresholds])

  const reset = () => setTriggeredThresholds([])

  return { reset }
}

export default useScrollDepth
