const senseColor = {
  black: {
    DEFAULT: "#000000",
  },
  white: {
    DEFAULT: "#FFFFFF",
  },
  gray: {
    100: "#F8F8FA",
    200: "#EFEFF5",
    300: "#E8E8F0",
    400: "#B9B9C5",
    500: "#9FA0AF",
    600: "#767685",
    700: "#626270",
    800: "#424350",
    900: "#21222E",
    1000: "#151515", // 디자인 가이드에는 없는 컬러
  },
  primary: {
    DEFAULT: "#FF8A00", // orange-700
    100: "#FFF9F2",
    200: "#FFE8CC",
    300: "#FFD6A6",
    400: "#FFC480",
    500: "#FFAD4D",
    600: "#FF9C26",
    700: "#FF8A00",
    800: "#FF7D06",
    900: "#FF660A",
  },
  error: {
    100: "#FFF4F4",
    200: "#FFA1A1",
    300: "#FF8080",
    400: "#FF6767",
    500: "#F23B3B",
    600: "#D82020",
    700: "#C31414",
    800: "#4E0000",
    900: "#230000",
  },
  success: {
    100: "#F3FCF5",
    200: "#A1E5AC",
    300: "#7EDB8D",
    400: "#4FCE63", // meeting
    500: "#14BD2F",
    600: "#11A529",
    700: "#0B741D",
    800: "#022B0B",
    900: "#001B07",
  },
  warning: {
    100: "#FFF8E8",
    200: "#FFE193",
    300: "#FFD772",
    400: "#FFCB44",
    500: "#FFBD14",
    600: "#DC9E00",
    700: "#AB7B00",
    800: "#4E3800",
    900: "#231900",
  },

  /**
   * 텍스트와 카드 디자인 등 다양한 컴포넌트와 배경에 사용되는 위계 질서를 구분하는 컬러입니다.
   */
  state: {
    DEFAULT: "#CADDFF",
  },

  /**
   * 이벤트 생성시 어떤 이벤트 유형을 선택하는지에 따라 캘린더 컬러가 표시가 변합니다.
   */
  eventColor: {
    DEFAULT: "#FF7B01", // 아무것도 선택안함
    gray: "#E0E0E0", // 지난 이벤트
    green: "#4FCE63", // 모임
    purple: "#A479FF", // 비즈니스
    pink: "#FF7B8B", // 데이트
    yellow: "#FFBD14", // 생일
    travel: "#3E97FF", // 여행
  },
}

export default senseColor
