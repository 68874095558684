import { METHOD } from "@runners/lib/http"
import api from "."
import { DjangoListResponse, DjangoResponse, ListQueryParams } from "./storeApi"

export const ProductQueryKeys = {
  all: () => ["product"],
  product: (params: { productId: number }) => [...ProductQueryKeys.all(), params],
  productOptionGroupList: (params: { productId: number }) => [...ProductQueryKeys.product(params), "optionGroupList"],
  productOptionList: (params: { productId: number }) => [...ProductQueryKeys.product(params), "optionList"],
  productList: (params?: StoreProductListParams) => [...ProductQueryKeys.all(), "productList", params],
  categoryList: (params?: ProductCategoryListParams) => [...ProductQueryKeys.all(), "categoryList", params],
  subCategoryList: (params?: ProductSubCategoryListParams) => [...ProductQueryKeys.all(), "subCategoryList", params],
  subCategoryDisclosure: (params: ProductCategoryParams) => [
    ...ProductQueryKeys.all(),
    "subCategoryDisclosure",
    params,
  ],
  category: (params: ProductCategoryParams) => [...ProductQueryKeys.all(), "category", params],

  subCategoryExcel: (params: ProductCategoryParams) => [...ProductQueryKeys.all(), "subCategoryExcel", params],
}

export type ProductCategoryDto = {
  id: number
  title: string
}

export type ProductSubCategoryDto = {
  id: number
  title: string
  product_category: number
}

type ProductCategoryListParams = ListQueryParams
type ProductCategoryParams = { categoryId: number }

type ProductCategoryResponse = DjangoResponse<{
  id: number
  title: string
  product_sub_categories: ProductSubCategoryDto[]
}>

type ProductSubCategoryListParams = ListQueryParams

type DisclosureOuterInputsDto = {
  id: number
  title: string
  description?: string | null
  inner_inputs: {
    id: number
    data_type: "text" | "checkbox"
    title: string
    place_holder: string
    value: string
  }[]
}

export type ProductDisclosureDto = {
  id: number
  title: string
  outer_inputs: DisclosureOuterInputsDto[]
}

type OptionGroupDto = {
  title: string
  options: { title: string }[]
}

type ProductOptionDto = {
  id?: number
  title?: string
  additional_price?: number
  price: number
  stock_count?: number | null
  is_public: boolean
}

export type ProductDto = {
  id: number
  product_category: ProductCategoryDto
  product_sub_category: ProductSubCategoryDto
  title: string
  description: string
  origin_price: number
  discount_price: number
  discount_rate: number
  is_tax: boolean
  stock_count: number | null
  sold_count: number
  started_at: string | null
  ended_at: string | null
  product_link?: string | null
  min_count_per_order: number | null
  max_count_per_order: number | null
  max_count_per_user: number | null
  status: ProductStatus
  on_sale_at: string | null
  images_data: string[]
  common_product_policy: number | null
  shipping_product_policy: number | null
  product_disclosure: ProductDisclosureDto
  is_adult_only: boolean
  created: string
  product_tags: string[]

  can_combine_shipping: boolean
  combine_shipping_product_policy?: null
}

export type ProductCreateDto = {
  title?: string
  product_category: number
  product_sub_category: number
  description?: string
  origin_price: number
  discount_price: number
  is_tax: boolean
  stock_count?: number | null
  min_count_per_order?: number
  max_count_per_order?: number
  max_count_per_user?: number
  images_data: string[]
  started_at?: string
  ended_at?: string
  product_link?: string
  common_product_policy?: number
  shipping_product_policy?: number
  option_groups: OptionGroupDto[]
  product_options: ProductOptionDto[]
  product_disclosure: ProductDisclosureDto
  product_tags: string[]

  can_combine_shipping: boolean
  combine_shipping_product_policy?: number
}

export type ProductUpdateDto = Partial<ProductCreateDto> & { status?: ProductStatus }

type DraftProductDto = {
  images_data: string[]
}

type PostStoreProductPayload = {
  storeId: number
  payload: ProductCreateDto | DraftProductDto
}

type PatchStoreProductPayload = {
  productId: number
  payload: ProductUpdateDto
}

export enum ProductStatus {
  승인대기 = "PENDING",
  승인보류 = "HOLD",
  판매중 = "ON_SALE",
  판매중지 = "SUSPEND",
  일시품절 = "OUT_OF_STOCK",
}

type ProductListDto = {
  id: number
  image_url: string | null
  title: string | null
  description: string | null
  origin_price: number
  sold_count: number
  stock_count: number
  status: ProductStatus | null
  on_sale_at: string | null
  started_at: string | null
  ended_at: string | null
  created: string
  modified: string
  product_category: ProductCategoryDto
  product_sub_category: ProductSubCategoryDto
  is_adult_only: boolean
}

export enum ProductDateType {
  CREATED = "created",
  ON_SALE_AT = "on_sale_at",
  ENDED_AT = "ended_at",
  MODIFIED = "modified",
}

export type StoreProductListParams = {
  storeId: number
  params?: ListQueryParams & {
    status?: ProductStatus
    product_category?: number
    product_sub_category?: number
    date_type?: ProductDateType
    start_date?: string
    end_date?: string
  }
}

const productApi = {
  getProduct: async ({ productId }: { productId: number }) => {
    const { data } = await api.get<DjangoResponse<ProductDto>>(`/business/product/${productId}`)
    return data
  },
  deleteProduct: async ({ productId }: { productId: number }) => {
    const { data } = await api.delete(`/business/product/${productId}`)
    return data
  },
  getStoreProductList: async ({ storeId, params }: StoreProductListParams) => {
    const { data } = await api.get<DjangoListResponse<ProductListDto>>(`/business/store/${storeId}/products`, {
      params,
    })
    return data
  },
  getProductOptionGroupList: async ({ productId }: { productId: number }) => {
    const { data } = await api.get<DjangoListResponse<OptionGroupDto>>(`/business/product/${productId}/option-groups`)
    return data
  },
  getProductOptionList: async ({ productId }: { productId: number }) => {
    const { data } = await api.get<DjangoListResponse<ProductOptionDto>>(`/business/product/${productId}/options`)
    return data
  },
  postStoreProduct: async ({ storeId, payload }: PostStoreProductPayload) => {
    const { data } = await api.post(`/business/store/${storeId}/product`, payload)
    return data
  },
  patchStoreProduct: async ({ productId, payload }: PatchStoreProductPayload) => {
    const { data } = await api.patch(`/business/product/${productId}`, payload)
    return data
  },
  getProductCategoryList: async (params?: ProductCategoryListParams) => {
    const { data } = await api.get<DjangoListResponse<ProductCategoryDto>>("/business/product/categories", { params })
    return data
  },

  getProductCategory: async ({ categoryId }: ProductCategoryParams) => {
    const { data } = await api.get<ProductCategoryResponse>(`/business/product/category/${categoryId}`)
    return data
  },

  getProductSubCategoryList: async (params?: ProductSubCategoryListParams) => {
    const { data } = await api.get<DjangoListResponse<ProductSubCategoryDto>>("/business/product/subcategories", {
      params,
    })
    return data
  },

  getProductSubCategoryDisclosure: async ({ categoryId }: ProductCategoryParams) => {
    const { data } = await api.get<DjangoResponse<ProductDisclosureDto>>(
      `/business/product/subcategory/${categoryId}/disclosure`,
    )
    return data
  },

  /**
   * 상품 태그 유효성 검사
   */
  postProductProductTagListCheck: async ({ product_tags }: { product_tags: string[] }) => {
    const { data } = await api(`/business/product/product-tags/check`, {
      method: METHOD.POST,
      data: { product_tags },
    })
    return data
  },

  /**
   * 서브 카테고리 엑셀 양식 조회
   */
  getProductSubcategoryExcel: async ({ subCategoryId }: { subCategoryId: number }) => {
    const { data } = await api(`/business/product/subcategory/${subCategoryId}/excel`)
    return data
  },
}
export default productApi
