import { useMutation } from "@tanstack/react-query"
import shippingPolicyApi from "@/apis/shipping-policy-api"
import storeApi from "@/apis/storeApi"
import { MutationOptions } from "@/types/mutation.type"

/** ------------------------------------------------------------------------------
 * 
 * 배송
 * 
 ------------------------------------------------------------------------------ */
export const usePostShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postStoreShippingPolicy,
    ...options,
  })
}

export const usePatchShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: shippingPolicyApi.patchShippingPolicy,
    ...options,
  })
}

export const usePostShippingPolicyCopy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: shippingPolicyApi.postShippingPolicyCopy,
    ...options,
  })
}

export const useDeleteShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: shippingPolicyApi.deleteShippingPolicy,
    ...options,
  })
}

/** ------------------------------------------------------------------------------
 * 
 * 묶음배송
 * 
 ------------------------------------------------------------------------------ */
export const usePostCombineShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postStoreCombineShippingPolicy,
    ...options,
  })
}

export const usePostCombineShippingPolicyCopy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postCombineShippingPolicyCopy,
    ...options,
  })
}

export const usePatchCombineShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.patchCombineShippingPolicy,
    ...options,
  })
}

export const useDeleteCombineShippingPolicy = (options: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.deleteCombineShippingPolicy,
    ...options,
  })
}

export const usePostCombineShippingPolicyTitleCheck = (options?: MutationOptions) => {
  return useMutation({
    mutationFn: storeApi.postCombineShippingPolicyTitleCheck,
    ...options,
  })
}
