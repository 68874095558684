import React, { PropsWithChildren } from "react"
import { cn } from "@runners/lib/utils"
import { type VariantProps } from "class-variance-authority"
import { buttonVariants } from "./button"

type LabelButtonPropsBase = Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "color">

export interface LabelButtonProps extends LabelButtonPropsBase, VariantProps<typeof buttonVariants>, PropsWithChildren {
  size: "56" | "48" | "40" | "36" | "32" | "30"
  mobileSize?: "56" | "48" | "40" | "36" | "32" | "30"
  outline?: boolean
  loading?: boolean
  htmlFor: string
}

const LabelButton = React.forwardRef<HTMLLabelElement, LabelButtonProps>(
  (
    { children, size, color = "primary", outline = false, loading = false, className, mobileSize, htmlFor, ...props },
    ref,
  ) => {
    return (
      <label
        ref={ref}
        htmlFor={htmlFor}
        className={cn(
          "cursor-pointer",
          buttonVariants({
            size,
            ...(mobileSize ? { mobileSize } : { mobileSize: size }),
            loading,
            ...(outline ? { outlineColor: color } : { color }),
          }),
          className,
        )}
        {...props}
      >
        {children}
      </label>
    )
  },
)
LabelButton.displayName = "LabelButton"

export default LabelButton
