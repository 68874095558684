import { Link, useLocation } from "react-router-dom"
import { Button, Menu } from "antd"
import type { MenuProps } from "antd"
import { CurrencyKrwIcon, KakaoLogoIcon, StarIcon, StoreFrontIcon, UserIcon, WrenchIcon } from "@/assets/icons"

type MenuItem = Required<MenuProps>["items"][number]
const items: MenuItem[] = [
  {
    key: "dashboard",
    label: "마이페이지",
    icon: <UserIcon className="size-4" />,
    children: [{ key: "/dashboard/my-info", label: <Link to="/dashboard/my-info">내 정보</Link> }],
  },
  {
    key: "dashboard/management",
    label: "운영관리",
    icon: <WrenchIcon className="size-4" />,
    children: [
      { key: "/dashboard/management/biz-info", label: <Link to="/dashboard/management/biz-info">사업자 정보</Link> },
      { key: "/dashboard/management/admin", label: <Link to="/dashboard/management/admin">관리자 조회</Link> },
    ],
  },
  {
    key: "dashboard/products",
    label: "상품관리",
    icon: <StoreFrontIcon className="size-4" />,
    children: [
      { key: "/dashboard/products/list", label: <Link to="/dashboard/products/list">상품조회</Link> },
      { key: "/dashboard/bulk-products/list", label: <Link to="/dashboard/bulk-products/list">상품 일괄등록</Link> },
      {
        key: "/dashboard/products/common-info",
        label: <Link to="/dashboard/products/common-info">공통정보 관리</Link>,
      },
      {
        key: "/dashboard/products/shipping-info",
        label: <Link to="/dashboard/products/shipping-info">배송정보 관리</Link>,
      },
      {
        key: "/dashboard/products/shipping-group-info",
        label: <Link to="/dashboard/products/shipping-group-info">배송비 묶음그룹 관리</Link>,
      },
    ],
  },
  {
    key: "dashboard/sales",
    label: "판매관리",
    icon: <StarIcon className="size-4" />,
    children: [
      { key: "/dashboard/sales/orders/list", label: <Link to="/dashboard/sales/orders/list">주문관리</Link> },
      { key: "/dashboard/sales/delivery/list", label: <Link to="/dashboard/sales/delivery/list">배송현황 관리</Link> },
      {
        key: "/dashboard/sales/order-cancel/list",
        label: <Link to="/dashboard/sales/order-cancel/list">취소관리</Link>,
      },
      {
        key: "/dashboard/sales/return-exchange/list",
        label: <Link to="/dashboard/sales/return-exchange/list">반품/교환 관리</Link>,
      },
    ],
  },

  {
    key: "dashboard/settlement",
    label: "정산관리",
    icon: <CurrencyKrwIcon className="size-4" />,
    children: [{ key: "/dashboard/settlement/list", label: <Link to="/dashboard/settlement/list">정산내역</Link> }],
  },
]
const Sidebar = () => {
  const location = useLocation()

  const selectedKeys = [location.pathname]
  const defaultOpenKeys = [location.pathname.split("/").slice(1, -1).join("/") || "dashboard"]

  return (
    <div className="relative h-full border-e border-e-gray-300">
      <nav className="flex flex-col gap-[60px]">
        <Menu
          className="!border-none"
          mode="inline"
          items={items}
          selectedKeys={selectedKeys}
          defaultOpenKeys={defaultOpenKeys}
        />

        <div className="p-5">
          <div className="relative h-[152px] rounded-[8px] bg-primary-100 p-4">
            <div className="mb-3">
              <p className="text-18 font-bold text-gray-900">사장님 오픈톡</p>
              <p className="text-14 text-gray-600">센스 사장님 오픈 채팅방</p>
            </div>
            <Button
              onClick={() => {
                window.open("https://open.kakao.com/o/gCyYXAjg", "_blank", "noopener,noreferrer")
              }}
              size="small"
              type="primary"
            >
              Go!
            </Button>

            <KakaoLogoIcon className="absolute bottom-4 right-4 h-[60px] opacity-15" />
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Sidebar
