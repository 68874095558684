import { useMediaQuery } from "@runners/hooks"
import { Image } from "@runners/ui"
import { WarningOutlineIcon } from "@/assets/icons"
import useScrollDepth from "@/hooks/use-scroll-depth"
import { trackingModule } from "@/lib/analytics"
import { GUIDE_MAP } from "./constant"

const ResponsiveSpace = () => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  if (isMobile) return <br />
  return " "
}

/** ------------------------------------------------------------------------------
 * 
 * 입점 가이드 페이지
 * 
 ------------------------------------------------------------------------------ */
const OnboardingPage = () => {
  useScrollDepth({
    thresholds: [25, 50, 75, 100],
    callback: threshold => {
      trackingModule(`scroll_depth_${threshold}%`, {
        utm_pathname: "/onboarding",
      })
    },
  })

  return (
    <>
      <div className="mx-auto w-full max-w-[1080px] py-20 mobile:px-5">
        <h1 className="text-center text-34 font-bold text-gray-900">
          센스 스토어
          <ResponsiveSpace />
          판매자 입점가이드
        </h1>

        {GUIDE_MAP.map(v => {
          return (
            <div key={`guide_${v.step}`} className="">
              <div className="h-[100px]" />
              <p className="text-center text-14 text-gray-500">STEP {v.step}</p>

              <h2 className="mt-6 text-center text-28 font-bold text-gray-800">{v.title}</h2>

              <p className="mt-3 text-center text-16 text-gray-700">{v.description}</p>
              <Image size="2560" src={v.src} className="mt-8 h-auto w-[1080px]" />
            </div>
          )
        })}

        <div className="mt-8 flex flex-col items-center justify-center gap-2">
          <div className="mt-3 flex w-fit items-center gap-2 rounded-2 bg-gray-100 px-4 py-3">
            <WarningOutlineIcon className="mt-0.5 size-4 shrink-0 text-gray-600" />
            <div className="flex flex-col gap-1 text-14 text-gray-800">
              <p>
                전자상거래법에 따라 상품을 등록하고 판매하기 위해서는 <strong>사업자등록번호</strong>와{" "}
                <strong>통신판매신고번호</strong>가 반드시 필요합니다.
              </p>
            </div>
          </div>

          <div className="mt-3 flex w-fit items-center gap-2 rounded-2 bg-gray-100 px-4 py-3">
            <WarningOutlineIcon className="mt-0.5 size-4 shrink-0 text-gray-600" />
            <div className="flex flex-col gap-1 text-14 text-gray-800">
              <p>
                정산계좌는 사업자등록증의 상호(법인일 경우 법인명) 또는 성명(법인일 경우 대표자)과{" "}
                <strong>동일한 이름의 계좌</strong>로 등록하셔야 합니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen bg-gray-100">
        <div className="mx-auto w-full max-w-[1080px] py-[60px]">
          <div className="flex gap-[120px] mobile:flex-col mobile:gap-6 mobile:px-5 md:items-center">
            <h4 className="w-fit shrink-0 text-24 font-bold text-gray-800">
              센스 스토어
              <br />
              판매자 교육 문의처
            </h4>

            <div className="flex-1 rounded-3 bg-white px-6 py-5">
              <div className="flex items-center gap-3">
                <p className="w-20 shrink-0 text-14 font-bold mobile:w-10">주소지</p>
                <div className="h-2.5 w-px bg-gray-300" />
                <p className="text-14 text-gray-700">
                  경기도 성남시 분당구 판교로 255번길 9-22, 판교 우림 W-CITY B동 508-2호
                </p>
              </div>
              <div className="mt-3 flex items-center gap-3">
                <p className="w-20 text-14 font-bold mobile:w-10">Tel</p>
                <div className="h-2.5 w-px bg-gray-300" />
                <p className="text-14 text-gray-700">070-7178-8777</p>
              </div>

              <div className="h-5" />
              <p className="text-14 text-eventColor-travel">www.store.sense.im</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OnboardingPage
