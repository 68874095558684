/**
 * 레이아웃 쉬프트를 방지용 BLANK
 */
export const CHAR_BLANK = "ㅤ"



/** ------------------------------------------------------------------------------
 * 
 * for store
 * 
 ------------------------------------------------------------------------------ */
export const statusOptionList = [
  { value: "PENDING", label: "승인대기" },
  { value: "HOLD", label: "승인보류" },
  { value: "ON_SALE", label: "판매중" },
  { value: "SUSPEND", label: "판매중지" },
  { value: "OUT_OF_STOCK", label: "일시품절" },
] as const

export enum OrderStatus {
  /** 결제 전 상태입니다. */
  // PENDING = "PENDING",
  /** 결제완료 */
  PAYMENT_COMPLETE = "PAYMENT_COMPLETE",
  /** 상품준비중 */
  PRODUCT_PREPARING = "PRODUCT_PREPARING",
  /** 배송중 */
  SHIPPING_ONGOING = "SHIPPING_ONGOING",
  /** 배송완료 */
  SHIPPING_COMPLETE = "SHIPPING_COMPLETE",
  /** 교환/환불 요청 */
  RETURN_EXCHANGE_REQUEST = "RETURN_EXCHANGE_REQUEST",
  /** 교환/환불 중 */
  RETURN_EXCHANGE_ONGOING = "RETURN_EXCHANGE_ONGOING",
  /** 교환/환불 완료 */
  RETURN_EXCHANGE_COMPLETE = "RETURN_EXCHANGE_COMPLETE",
  /** 주문취소 완료 */
  CANCEL_COMPLETE = "CANCEL_COMPLETE",
}


/**
 * 프론트에서 쓰기쉽게
 */
export const OrderStatusLabelMap = {
  // [OrderStatus.PENDING]: "입금대기",
  [OrderStatus.PAYMENT_COMPLETE]: "결제완료",
  [OrderStatus.PRODUCT_PREPARING]: "상품준비중",
  [OrderStatus.SHIPPING_ONGOING]: "배송중",
  [OrderStatus.SHIPPING_COMPLETE]: "배송완료",
  [OrderStatus.RETURN_EXCHANGE_REQUEST]: "교환/반품요청",
  [OrderStatus.RETURN_EXCHANGE_ONGOING]: "교환/반품중",
  [OrderStatus.RETURN_EXCHANGE_COMPLETE]: "교환/반품완료",
  [OrderStatus.CANCEL_COMPLETE]: "주문취소완료",
}


export const orderStatusList = Object.entries(OrderStatusLabelMap).map(([value, label]) => ({
  label,
  value: value as OrderStatus
}));


/** ------------------------------------------------------------------------------
 * 
 * 배송 상태
 * 
 ------------------------------------------------------------------------------ */
export enum DeliveryStatus {
  /** 상품준비중 */
  PRODUCT_PREPARING = "PRODUCT_PREPARING",
  /** 배송중 */
  SHIPPING_ONGOING = "SHIPPING_ONGOING",
  /** 배송완료 */
  SHIPPING_COMPLETE = "SHIPPING_COMPLETE"
}

/**
 * 프론트에서 쓰기쉽게
 */
export const DeliveryStatusLabelMap = {
  [OrderStatus.PRODUCT_PREPARING]: "상품준비중",
  [OrderStatus.SHIPPING_ONGOING]: "배송중",
  [OrderStatus.SHIPPING_COMPLETE]: "배송완료",
}


export const deliveryStatusList = Object.entries(DeliveryStatusLabelMap).map(([value, label]) => ({
  label,
  value: value as DeliveryStatus
}));