"use client"

import { useEffect } from "react"

interface UseKeydownFunctionProps {
  key: string | string[]
  fn: () => void
  enabled?: boolean
}

/** ------------------------------------------------------------------------------
 * 
 * 모달 등에서 ESC를 눌렀을때 모달의 close가 동작하도록 설계한 훅
 * 
 * @key - e.key값
 * 
 ------------------------------------------------------------------------------ */
export const useKeydownFunction = (props: UseKeydownFunctionProps) => {
  const { key, fn, enabled } = props
  useEffect(() => {
    if (!enabled) return

    const onKeyDown = (e: KeyboardEvent) => {
      // 단일 key
      if (typeof key === "string") {
        e.key === key && fn()
      }
      // e.key 배열
      if (Array.isArray(key)) {
        key.includes(e.key) && fn()
      }
    }

    window.addEventListener("keydown", onKeyDown)
    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [fn])
}
