import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "./App.tsx"

import "./globals.css"
import { initSentry } from "./lib/sentry/init"

initSentry()

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
