import { Button } from "@runners/ui"
import { Button as AntdButton } from "antd"
import { DownloadIcon, StoreFrontIcon } from "@/assets/icons"
import useModalStack from "@/hooks/use-modal"
import BulkProductsTable from "./_components/bulk-products-table"
import { useGetLastBulkProductsQuery } from "./hooks/use-get-query"
import ExcelBulkProductsModal from "./modal/excel-bulk-products-modal"
import ImageStorageModal from "./modal/image-storage-modal"
import DashboardHeader from "../../components/dashboard-header"

const BREADCRUMB_ITEMS = [
  {
    title: (
      <div className="flex items-center gap-1">
        <StoreFrontIcon className="size-3" />
        <span>상품 일괄등록</span>
      </div>
    ),
    href: "/dashboard/bulk-products",
  },
  { title: "상품 일괄등록" },
]

const BulkProductsListPage = () => {
  const modalStack = useModalStack()

  const openImageModal = () => {
    modalStack.open({
      Component: ImageStorageModal,
      key: "image-storage-modal",
      componentProps: {
        title: "이미지 업로드",
        layoutClassName: "w-[720px]",
      },
    })
  }

  const openBulkProductModal = () => {
    modalStack.open({
      Component: ExcelBulkProductsModal,
      key: "bulk-products-modal",
      componentProps: {
        title: "엑셀 일괄등록",
        layoutClassName: "w-[680px]",
      },
    })
  }

  const { data } = useGetLastBulkProductsQuery()

  return (
    <div>
      <DashboardHeader title={"상품 일괄등록"} breadcrumbItems={BREADCRUMB_ITEMS} />
      <div className="flex items-center justify-between p-6">
        <div className="mb-6 flex items-center gap-4">
          <h2 className="text-20 font-bold text-gray-900">총 {data?.total_count || 0}건</h2>
          <span className="text-18 font-normal">
            (성공: {data?.success_count || 0} / 실패: {data?.fail_count || 0})
          </span>
          {data?.failed_excel_url && (
            <AntdButton
              className="text-14 text-gray-700"
              onClick={() => {
                if (!data?.failed_excel_url) return
                window.open(data?.failed_excel_url, "_blank")
              }}
            >
              <DownloadIcon className="size-4" />
              실패 엑셀 다운로드
            </AntdButton>
          )}
        </div>

        <div className="flex gap-4">
          <Button size="32" color="white" outline className="font-medium" onClick={openImageModal}>
            이미지 업로드
          </Button>
          <Button size="32" color="primary" onClick={openBulkProductModal}>
            엑셀 일괄등록
          </Button>
        </div>
      </div>
      <BulkProductsTable />

      <div className="h-20" />
    </div>
  )
}

export default BulkProductsListPage
