import React from "react"
import { Link } from "react-router-dom"
import { Button, ButtonProps } from "@runners/ui"

interface LinkButtonProps extends ButtonProps {
  href: string
  target?: string
  linkClassName?: string
}

const LinkButton = ({ href, target, children, linkClassName, ...restProps }: LinkButtonProps) => {
  return (
    <Link to={href} target={target} className={linkClassName}>
      <Button {...restProps}>{children}</Button>
    </Link>
  )
}

export default LinkButton
