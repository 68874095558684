import { METHOD, OrderStatus } from "@runners/lib"
import api from "."
import { ParcelCompanyDto } from "./businessApi"
import { DjangoListResponse, DjangoResponse, ListQueryParams } from "./storeApi"

export const OrderQueryKeys = {
  all: () => ["order"],
  order: (params: { orderId: number }) => [...OrderQueryKeys.all(), params.orderId],
  list: (params?: ListQueryParams) => [...OrderQueryKeys.all(), "list", params],

  resolutionList: ({ ...params }: ListQueryParams) => [...OrderQueryKeys.all(), "resolution-list", params],
  resolution: (params: { orderResolutionId: number }) => [
    ...OrderQueryKeys.all(),
    "resolution",
    params.orderResolutionId,
  ],
}

interface OrderListDto {
  id: number
  order_number: string
  product_number: string
  created: string
  status: OrderStatus | null
  order_title: string
  option_title: string
  count: number
  price: number
  payment_method: string
  orderer_name: string
  receiver_name: string
}

export interface StoreOrderListParams {
  storeId: number
  params?: {
    receiver_name?: string
    orderer_name?: string
    orderer_phone?: string
    order_number?: string
    product_number?: string
    tracking_number?: string
    start_date?: string
    end_date?: string
    status?: string
  } & ListQueryParams
}

export interface ShippingProductPolicyDto {
  address: string
  detail_address: string
  etc_extra_fee: null
  exchange_fee: number
  fee_target_type: string
  fee_type: string
  id: number
  is_direct: boolean
  is_extra_fee: boolean
  is_pre_paid: boolean
  is_quick: boolean
  jeju_extra_fee: null
  parcel_company: ParcelCompanyDto
  policy_number: string
  return_exchange_parcel_company: ParcelCompanyDto
  return_fee: number
  shipping_type: string
  sub_shipping_product_policy: {
    charge_per_count: null
    free_shipping_threshold: null
    shipping_fee: number
  }
  title: string
  zip_code: string
}

export interface ProductDto {
  id: number
  common_product_policy: {
    id: number
    content: string
    title: string
  }
  image_url: string
  title: string
  shipping_product_policy: ShippingProductPolicyDto
  store_title: string
}

export interface OrderProductGroupDto {
  id: number
  image_url: string
  order_products: { id: number; title: string; count: number }[]
  product_id: number
  status: OrderStatus
  title: string
  total_price: number
  order_resolution: { id: number; order_resolution_choice_title: string } | null
}

export interface OrderDto {
  id: number
  address: string
  created: string
  order_number: string
  order_product_groups: OrderProductGroupDto[]
  orderer_name: string
  receiver_name: string
}

export interface OrderResolutionsParams extends ListQueryParams {
  order_status?: "CANCEL" | "RETURN_EXCHANGE"
  status?: "PENDING" | "COMPLETE"
  start_date?: string
  end_date?: string
}

export interface OrderResolutionDto {
  id: number
  address: string
  created: string
  detail_reason: string | null
  exchange_product_option_title: string | null
  images_data: string[]
  order_number: string
  ordered_at: string
  order_product_group: OrderProductGroupDto
  order_resolution_choice: OrderResolutionChoiceDto
  order_title: string
  option_title: string
  count: string
  price: string
  payment_method: string
  orderer_name: string
  receiver_name: string

  status: "COMPLETE" | "PENDING"
  type: "CANCEL" | "RETURN_EXCHANGE"
}

export interface OrderResolutionChoiceDto {
  id: number
  title: string
  liability: "CUSTOMER" | "SELLER"
}

const orderApi = {
  /**
   * 주문 처리 리스트 조회
   */
  getStoreOrderResolutions: async ({ storeId, params }: { storeId: number; params: OrderResolutionsParams }) => {
    const { data } = await api<DjangoListResponse<OrderResolutionDto>>(`/business/store/${storeId}/order-resolutions`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 주문 처리 객체 조회
   */
  getOrderResolution: async ({ orderResolutionId }: { orderResolutionId: number }) => {
    const { data } = await api.get<DjangoResponse<OrderResolutionDto>>(
      `/business/order-resolution/${orderResolutionId}`,
    )
    return data
  },

  getStoreOrderList: async ({ storeId, params }: StoreOrderListParams) => {
    const { data } = await api.get<DjangoListResponse<OrderListDto>>(`/business/store/${storeId}/orders`, {
      params,
    })
    return data
  },

  getOrder: async ({ orderId }: { orderId: number }) => {
    const { data } = await api.get<DjangoResponse<OrderDto>>(`/business/order/${orderId}`)
    return data
  },

  /**
   * 주문 처리 사유 리스트 조회
   */
  // getOrderResolutionChoices: async (params:  & { liability?: "CUSTOMER" | "SELLER" }) => {
  getOrderResolutionChoices: async (params: any) => {
    const { data } = await api<DjangoListResponse<OrderResolutionChoiceDto>>(`business/order/resolution-choices`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 주문상품 주문취소
   */
  postOrderProductCancel: async ({ orderId, productId }: { orderId: number; productId: number }) => {
    const { data } = await api(`business/order/${orderId}/product/${productId}/cancel`, { method: METHOD.POST })
    return data
  },

  /**
   * 주문 처리 수정
   */
  patchOrderResolution: async ({
    orderResolutionId,
    payload,
  }: {
    orderResolutionId: number
    payload: { status: "PENDING" | "COMPLETE" }
  }) => {
    const { data } = await api(`business/order-resolution/${orderResolutionId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },
}

export default orderApi
