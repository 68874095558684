import { cn } from "@runners/lib/utils"
import styles from "./spinner.module.css"

interface SpinnerProps {
  className?: string
}

/**
 * 디자이너분들이 요청해주신 센스 로딩 스피너입니다
 *
 * @see https://bbbootstrap.com/snippets/ring-spinner-loader-using-css-and-html-63692067
 * 여기 참고해서 만들었습니다.
 *
 * @comment 이렇게 불규칙하게 돌아가는 스피너를 ring 이라고 하더라고요.
 */
const Spinner = ({ className }: SpinnerProps) => {
  return (
    <svg className={cn(styles.spinner, className)} viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke="#EEEEEE"></circle>
      <circle className={styles.path} cx="25" cy="25" r="20" stroke="#FF8A00" fill="none" strokeWidth="5"></circle>
    </svg>
  )
}

export default Spinner
