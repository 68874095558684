import { XIcon } from "@/assets/icons"
import cn from "@/utils/cn"

interface ModalContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  close?: () => void
}

const ModalContainer = ({
  title,
  className,
  close,
  children,
  ...restProps
}: React.PropsWithChildren<ModalContainerProps>) => {
  return (
    <div className={cn("rounded-2 bg-white", className)} {...restProps}>
      <header className="flex justify-between px-5 pb-3 pt-6">
        <h1 className="text-20 font-bold text-gray-900">{title}</h1>
        <button onClick={close} className="flex size-8 items-center justify-center">
          <XIcon className="size-6" />
        </button>
      </header>
      {children}
    </div>
  )
}

export default ModalContainer
