import { useSearchParams } from "react-router-dom"
import { Button } from "antd"
import { ArrowCounterClockwiseIcon, StarIcon } from "@/assets/icons"
import ReturnExchangeTable from "./_components/return-exchange-table"
import DashboardHeader from "../../components/dashboard-header"
import DurationFilter from "../orders/components/duration-filter"
import PaymentFilter from "../orders/components/payment-filter"
import SearchFilter from "../orders/components/search-filter"

const BREADCRUMB_ITEMS = [
  {
    title: (
      <div className="flex items-center gap-1">
        <StarIcon className="size-3" />
        <span>반품/교환 관리</span>
      </div>
    ),
    href: "/dashboard/sales",
  },
  { title: "반품/교환 관리" },
]

const ReturnExchangePage = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleResetClick = () => {
    setSearchParams({})
  }

  return (
    <div>
      <DashboardHeader title="반품/교환 관리" breadcrumbItems={BREADCRUMB_ITEMS} />
      <div className="p-6 pb-20">
        {/* 상단 검색 필터 및 초기화 버튼 */}
        <div className="flex items-center justify-between">
          <SearchFilter key={searchParams.get("search")} />
          <Button type="default" onClick={handleResetClick} className="gap-1 font-bold text-gray-500">
            <ArrowCounterClockwiseIcon className="size-4" />
            초기화
          </Button>
        </div>
        <div className="my-6 h-px w-full bg-gray-300" />
        <DurationFilter />
        <div className="my-3 h-px w-full bg-gray-300" />
        <PaymentFilter />
        <div className="my-6 h-px w-full bg-gray-300" />
        <ReturnExchangeTable />
      </div>
    </div>
  )
}

export default ReturnExchangePage
