import { useQuery } from "@tanstack/react-query"
import orderApi, { OrderQueryKeys, OrderResolutionsParams } from "@/apis/order-api"
import useMyInfo from "@/hooks/use-my-info"

export const useGetOrderResolutionsQuery = (params: OrderResolutionsParams) => {
  const _storeId = useMyInfo()?.store
  const storeId = Number(_storeId)

  return useQuery({
    queryKey: OrderQueryKeys.resolutionList(params),
    queryFn: () => orderApi.getStoreOrderResolutions({ params, storeId }),
    enabled: !!_storeId,
    select: response => response.data,
  })
}

export const useGetOrderResolutionQuery = ({ orderResolutionId }: { orderResolutionId: number }) => {
  return useQuery({
    queryKey: OrderQueryKeys.resolution({ orderResolutionId }),
    queryFn: () => orderApi.getOrderResolution({ orderResolutionId }),
    select: response => response.data,
  })
}
