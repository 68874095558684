import { OrderStatus } from "@runners/lib"
import { Button, Spinner } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import { OrderQueryKeys } from "@/apis/order-api"
import withModalLayout from "@/hoc/with-modal-layout"
import { useAlert } from "@/hooks/use-alert"
import useToast from "@/hooks/use-toast"
import OrderResolutionDetailInfo from "./order-resolution-detail-info"
import OrdererInfo from "./orderer-info"
import ReturnExchangeChoice from "./return-exchange-choice"
import { useGetOrderResolutionQuery } from "../../../order-cancel/_hooks/use-get-query"
import { usePatchOrderResolutionMutation } from "../../../order-cancel/_hooks/use-mutation"
import { CancelReturnContextProvider } from "../../_hooks/use-cancel-return-context"

interface OrderResolutionModalProps {
  orderResolutionId: number
}

const OrderResolutionModal = withModalLayout<OrderResolutionModalProps>(props => {
  const { orderResolutionId, close } = props

  const queryClient = useQueryClient()
  const { data } = useGetOrderResolutionQuery({ orderResolutionId })

  const { mutateAsync } = usePatchOrderResolutionMutation({
    onError: e => {
      // eslint-disable-next-line no-console
      console.log(`e`, e)
      toast.error("교환/반품 승인 실패")
    },
  })

  const alert = useAlert()
  const toast = useToast()
  const openModal = () => {
    alert.open({
      title: "교환/반품을 승인하시겠어요?",
      content: "승인 후에는 다시 되돌릴 수 없어요.",
      onConfirm: async () => {
        await mutateAsync({ orderResolutionId, payload: { status: "COMPLETE" } })
        const product = data?.order_product_group

        toast.success(`${product?.title} 교환/반품이 승인되었어요.`)
        queryClient.invalidateQueries({ queryKey: OrderQueryKeys.resolution({ orderResolutionId }) })
        queryClient.invalidateQueries({ queryKey: OrderQueryKeys.resolutionList({}) })
      },
    })
  }

  if (!data)
    return (
      <div className="flex items-center justify-center py-20">
        <Spinner />
      </div>
    )

  const isCancel = data.type === "CANCEL"

  return (
    <CancelReturnContextProvider isCancel={isCancel} orderResolutionId={orderResolutionId}>
      <div>
        <main className="p-5">
          <OrdererInfo />

          <div className="">
            <p className="shrink-0 basis-[80px] text-14 font-bold text-gray-900">
              {isCancel ? "상품 정보" : "상세 목록"}
            </p>

            <div className="mt-2 flex max-h-[50vh] flex-col gap-2 overflow-auto">
              <OrderResolutionDetailInfo />
              <div className="my-[14px] h-px bg-gray-300" />
              <ReturnExchangeChoice />
            </div>
          </div>
        </main>

        <footer className="flex gap-2 px-5 py-4">
          <Button size="40" color="gray" className="flex-1" onClick={close}>
            닫기
          </Button>

          {!isCancel && data.order_product_group.status !== OrderStatus.RETURN_EXCHANGE_ONGOING && (
            <Button size="40" color="primary" className="flex-1" onClick={openModal}>
              교환/환불 승인
            </Button>
          )}
        </footer>
      </div>
    </CancelReturnContextProvider>
  )
})

export default OrderResolutionModal
