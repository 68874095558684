import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import storeApi, { StoreImagesParams, StoreQueryKeys } from "@/apis/storeApi"
import useMyInfo from "@/hooks/use-my-info"

export const useGetLastBulkProductsQuery = () => {
  const myInfo = useMyInfo()

  const storeId = myInfo?.store || 0

  return useQuery({
    queryKey: StoreQueryKeys.bulkProduct.lastHistory({ storeId }),
    queryFn: () => storeApi.getStoreProductUploads({ storeId }),
    enabled: !!storeId,
    select: response => response.data,
  })
}

export const useGetStoreImagesQuery = (params: Omit<StoreImagesParams, "page">) => {
  const myInfo = useMyInfo()
  const storeId = myInfo?.store || 0

  return useInfiniteQuery({
    queryKey: StoreQueryKeys.storeImage.params({ storeId, params }),
    queryFn: ({ pageParam = 1 }) => storeApi.getStoreImages({ storeId, params: { ...params, page: pageParam } }),
    getNextPageParam: (lastPage, allPage) => {
      if (!lastPage?.next) {
        return null
      }
      return allPage.length + 1
    },
    initialPageParam: 1,
  })
}
