import { useSearchParams } from "react-router-dom"
import { Checkbox, CheckboxProps } from "antd"

interface CommonFilterProps {
  key: string
  list: { label: string; value: string }[]
  label: string
}

const CommonFilter = (props: CommonFilterProps) => {
  const { key, list, label } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get(key) ? searchParams.get(key)!.split(",") : []

  const checkAll = !status.length

  const handleCheckAllChange: CheckboxProps["onChange"] = () => {
    searchParams.delete(key)
    setSearchParams(searchParams)
  }

  const handleCheckboxChange: CheckboxProps["onChange"] = e => {
    const { value, checked } = e.target

    let updateStatus

    if (checked) {
      updateStatus = [...status, value]
    } else {
      updateStatus = checkAll
        ? list.map(({ value }) => value).filter(v => v !== value)
        : status.filter(v => v !== value)
    }

    if (updateStatus.length === list.length) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, updateStatus.join(","))
    }
    setSearchParams(searchParams)
  }

  return (
    <div className="flex items-center gap-5 [&_.ant-checkbox+span]:pr-0">
      <span className="w-[140px] text-14 text-gray-900">{label}</span>

      <Checkbox checked={checkAll} onChange={handleCheckAllChange}>
        전체
      </Checkbox>

      {list.map(({ value, label }) => (
        <Checkbox
          key={`${value}-${label}`}
          value={value}
          checked={checkAll || status.includes(value)}
          onChange={handleCheckboxChange}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  )
}

export default CommonFilter
