import { Breadcrumb } from "antd"
import type { ItemType } from "antd/es/breadcrumb/Breadcrumb"

interface DashboardHeaderProps {
  title: string
  breadcrumbItems: ItemType[]
}

const DashboardHeader = ({ title, breadcrumbItems }: DashboardHeaderProps) => (
  <div className="flex flex-col gap-4 bg-gray-100 p-6">
    <Breadcrumb items={breadcrumbItems} />
    <h1 className="text-28 font-bold text-gray-900">{title}</h1>
  </div>
)

export default DashboardHeader
