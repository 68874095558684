import { useQuery } from "@tanstack/react-query"
import api, { METHOD } from "@/apis"
import { DjangoListResponse, DjangoResponse } from "./storeApi"

export interface NoticeListDto {
  id: number
  type: "GENERAL" | "PROMOTION"
  title: string
}

export interface NoticeDto {
  id: number
  type: "GENERAL" | "PROMOTION"
  title: string
  content: string
}

export type AdminNoticeListParams = {
  type?: string
}

export const noticeQueryKey = {
  notice: () => ["notice"],
  noticeList: (params?: AdminNoticeListParams) => ["notice", "list", { ...params }],
  noticeDetail: (noticeId: number) => ["notice", "detail", noticeId],
}

const noticeApi = {
  getNoticeList: async (params?: AdminNoticeListParams) => {
    const { data } = await api.get<DjangoListResponse<NoticeListDto>>(`/notices`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  getNotice: async (noticeId: number) => {
    const { data } = await api.get<DjangoResponse<NoticeDto>>(`/notice/${noticeId}`, {
      method: METHOD.GET,
    })
    return data
  },
}

export default noticeApi

export const useGetNoticeListQuery = (params?: AdminNoticeListParams) => {
  return useQuery({
    queryKey: noticeQueryKey.noticeList(params),
    queryFn: () => noticeApi.getNoticeList(params),
  })
}

export const useGetNoticeQuery = (noticeId: number, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: noticeQueryKey.noticeDetail(noticeId),
    queryFn: () => noticeApi.getNotice(noticeId),
    enabled: options?.enabled,
  })
}
