/** 정산 상태 */
export enum SettlementStatus {
  /** 정산대기중 */
  PENDING = "PENDING",
  /** 정산실패 */
  FAILED = "FAILED",
  /** 정산완료 */
  COMPLETED = "COMPLETED",
}

/** 결제 방법 */
export enum PaymentMethod {
  /** 신용/체크카드 */
  CARD = "CARD",
  /** 계좌이체 */
  BANK_TRANSFER = "BANK_TRANSFER",
  /** 핸드폰 결제 */
  MOBILE = "MOBILE",
  /** 가상계좌 */
  VIRTUAL_ACCOUNT = "VIRTUAL_ACCOUNT",
  /** 무통장입금 */
  DIRECT_BANK_DEPOSIT = "DIRECT_BANK_DEPOSIT",
}

/** 배송 타입 */
// 현재 업체배송 1개 상태 밖에 없으나 추후 추가 가능해서 export enum 입니다
export enum SettlementShippingType {
  /** 업체배송 */
  COMPANY = "COMPANY",
}
