"use client"

import { useState } from "react"
import { useIsomorphicLayoutEffect } from "../server"

/**
 * @example
 * ```
 * const isMobile = useMediaQuery("(max-width: 768px)")
 * ```
 */
export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean | null>(null)

  useIsomorphicLayoutEffect(() => {
    const queryList = window.matchMedia(query)
    const listener = () => setMatches(queryList.matches)
    listener()
    queryList.addEventListener("change", listener)
    return () => queryList.removeEventListener("change", listener)
  }, [query])

  return matches
}
