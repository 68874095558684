import { useMemo } from "react"
import { toLocale } from "@runners/lib/price"
import { Badge } from "@runners/ui"
import { BadgeColor } from "@runners/ui/src/badge"
import { Table } from "antd"
import dayjs from "dayjs"
import useModalStack from "@/hooks/use-modal"
import { SettlementStatus } from "../constants"
import SettlementDetailModal from "./modal/settlement-detail-modal"
import { useTableFilterContext } from "../context/table-filter-context"
import { useGetSettlementListQuery } from "../hooks/use-get-query"

const columns = [
  { key: "number", title: "No.", dataIndex: "number" },
  { key: "title", title: "구분", dataIndex: "title" },
  { key: "count", title: "건수", dataIndex: "count" },
  { key: "settlement_cycle", title: "정산주기", dataIndex: "settlement_cycle" },
  { key: "total_sales_price", title: "판매가 합계", dataIndex: "total_sales_price" },
  { key: "total_supply_price", title: "공급가 합계", dataIndex: "total_supply_price" },
  { key: "tax_amount", title: "세금", dataIndex: "tax_amount" },
  { key: "pg_fee", title: "PG 수수료", dataIndex: "pg_fee" },
  { key: "total_settlement_amount", title: "정산금액합계", dataIndex: "total_settlement_amount" },
  { key: "status", title: "정산현황", dataIndex: "status" },
  { key: "expected_date", title: "정산예정일", dataIndex: "expected_date" },
  { key: "etc", title: "비고", dataIndex: "etc" },
  { key: "popbill", title: "세금계산서", dataIndex: "popbill" },
]

const SettlementTable = () => {
  const { dates, checkboxState } = useTableFilterContext()

  const startDate = dates?.[0]
  const endDate = dates?.[1]

  const params = useMemo(() => {
    let status = [
      checkboxState.waiting && SettlementStatus.PENDING,
      checkboxState.failed && SettlementStatus.FAILED,
      checkboxState.complete && SettlementStatus.COMPLETED,
    ].filter(Boolean) as SettlementStatus[]

    if (status.length === 3) {
      status = []
    }

    return {
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
      status,
    }
  }, [dates, checkboxState])

  const { data = [] } = useGetSettlementListQuery({
    ...params,
  })

  const dataSource = data.map((item, index) => ({
    key: index,
    number: index + 1,
    title: item.title,
    count: item.count,
    settlement_cycle: item.settlement_cycle,
    total_sales_price: toLocale(item.total_sales_price),
    total_supply_price: toLocale(item.total_supply_price),
    tax_amount: toLocale(item.tax_amount),
    pg_fee: toLocale(item.pg_fee),
    total_settlement_amount: toLocale(item.total_settlement_amount),
    status: StatusBadge({ status: item.status }),
    expected_date: dayjs(item.expected_date).format("YYYY-MM-DD"),
    etc: (
      <button onClick={() => openDetailModal(item.id)}>
        <span className="text-14 text-gray-700">상세정보</span>
      </button>
    ),
    popbill: (
      <a href={"https://www.popbill.com/"} className="text-eventColor-travel hover:underline" target="_blank">
        발행하기
      </a>
    ),
  }))

  const modalStack = useModalStack()

  const openDetailModal = (settlementGroupId: number) => {
    modalStack.open({
      key: `settlement-detail-modal-${settlementGroupId}`,
      Component: SettlementDetailModal,
      componentProps: {
        title: "상세정보",
        layoutClassName: "w-[1200px]",
        settlementGroupId,
      },
    })
  }

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{
        position: ["bottomCenter"],
      }}
    />
  )
}

export default SettlementTable

/**
 * 정산상태 뱃지
 */
const StatusBadge = ({ status }: { status: SettlementStatus }) => {
  let color: BadgeColor
  let text = ""

  switch (status) {
    case SettlementStatus.PENDING:
      color = "gray"
      text = "정산대기중"
      break
    case SettlementStatus.COMPLETED:
      color = "blue"
      text = "정산완료"
      break
    case SettlementStatus.FAILED:
      color = "red"
      text = "정산실패"
      break
  }

  return <Badge color={color}>{text}</Badge>
}
