"use client"

import React, { createContext, useContext, useState } from "react"
import { cn } from "@runners/lib/utils"

interface TabContextProps<T> {
  current: T
  list: T[] | readonly T[]
  onClick: (value: T) => void
}

// CreateContext를 제네릭으로 정의하여 유연하게 사용
const CreateContext = createContext<TabContextProps<any> | null>(null)

const useTabInnerContext = <T,>() => {
  const contextState = useContext(CreateContext as React.Context<TabContextProps<T> | null>)
  if (!contextState) {
    throw new Error("UI 패키지 내에서 Context를 확인해보세요")
  }
  return contextState
}

export const useTabContext = <T,>() => {
  const contextState = useContext(CreateContext as React.Context<TabContextProps<T> | null>)
  if (!contextState) {
    throw new Error("UI 패키지 내에서 Context를 확인해보세요")
  }
  return { current: contextState.current, list: contextState.list }
}

export interface TabsContextProps<T> {
  list: any[] | readonly any[]
  initialValue?: T
  children: React.ReactNode
  className?: string
  itemClassName?: string
  onClick?: (value: T) => void
}

// TabsContext에 제네릭을 추가하여 list 타입이 유연하도록 함
const TabsContext = <T,>({
  list,
  initialValue,
  children,
  className,
  itemClassName,
  onClick = () => {},
}: TabsContextProps<T>) => {
  const [currentSelect, setCurrentSelect] = useState<T>(initialValue ?? list[0])

  const onClickTab = (value: T) => {
    setCurrentSelect(value)
    onClick(value)
  }

  return (
    <CreateContext.Provider value={{ current: currentSelect, onClick: onClickTab, list }}>
      <div className="flex flex-col">
        <div className={cn("flex w-full flex-1 justify-around", className)}>
          {list.map((item, index) => (
            <Tab key={typeof item === "string" ? item : index} item={item} className={itemClassName} />
          ))}
        </div>
        <div className="relative h-[3px] w-full bg-gray-300 mobile:h-px">
          <div
            className="absolute h-[3px] bg-primary-800 transition-all duration-300 ease-in-out mobile:bottom-0 mobile:h-[3px]"
            style={{
              width: `calc(100% / ${list.length})`,
              transform: `translateX(${list.findIndex(value => value === currentSelect) * 100}%)`,
            }}
          />
        </div>
        {children}
      </div>
    </CreateContext.Provider>
  )
}

export default TabsContext

const Tab = <T,>({ item, className }: { item: T; className?: string }) => {
  const { current, onClick } = useTabInnerContext<T>()

  return (
    <div
      className={cn(
        "flex-1 cursor-pointer py-3 text-center text-16 leading-[28px]",
        {
          "text-primary-700 font-bold": current === item,
          "text-gray-900 mobile:text-gray-700 mobile:font-medium": current !== item,
        },
        className,
      )}
      onClick={() => onClick(item)}
    >
      {typeof item === "string" ? item : (item as any).label}
    </div>
  )
}

const TabContent = <T,>({ value, children }: { value: T; children: React.ReactNode }) => {
  const { current } = useTabContext<T>()

  if (value === "showAll") {
    return <>{children}</>
  }

  if (current === value) {
    return <>{children}</>
  }

  return null
}

TabsContext.Content = TabContent
