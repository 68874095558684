import { Dispatch, SetStateAction, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { Image, useTabContext } from "@runners/ui"
import { ConfigProvider, Select, Table } from "antd"
import { EmptyTrash } from "@/components/empty"
import useMyInfo from "@/hooks/use-my-info"
import useToast from "@/hooks/use-toast"
import { TAB_LIST } from "."
import { useGetStoreImagesQuery } from "../../hooks/use-get-query"
import { useStoreImagesCopyMutation } from "../../hooks/use-mutation"

/** ------------------------------------------------------------------------------
   * 
   * antd table
   * 
   ------------------------------------------------------------------------------ */
const columns = [
  {
    key: "preview",
    dataIndex: "preview",
    title: "이미지",
    width: "25%",
  },
  {
    key: "urlText",
    dataIndex: "urlText",
    title: "이미지 URL",
    width: "50%",
  },
  {
    key: "more",
    dataIndex: "more",
    title: "이미지 복제",
  },
]

const totalOptions = [
  { label: "대표 이미지 복제", value: "THUMBNAIL" },
  { label: "상세페이지 복제", value: "DETAIL" },
  { label: "추가 이미지 복제", value: "ADDITION" },
]

interface ImageStorageTableProps {
  selectedRows: any[]
  setSelectedRows: Dispatch<SetStateAction<any[]>>
}

const ImageStorageTable = (props: ImageStorageTableProps) => {
  const { selectedRows, setSelectedRows } = props

  const { current } = useTabContext<(typeof TAB_LIST)[number]>()

  const toast = useToast()
  const myInfo = useMyInfo()
  const storeId = myInfo?.store || 0

  const rowSelection = {
    selectedRowKeys: selectedRows.map(row => row.key),
    onChange: (_: any, rows: any[]) => {
      setSelectedRows(rows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  }

  const { data, isFetching, hasNextPage, fetchNextPage } = useGetStoreImagesQuery({
    page_size: 10,
    type: current.value,
  })

  /**
   * copy mutation
   */
  const copyMutation = useStoreImagesCopyMutation({
    onSuccess: (_, variables) => {
      const {
        payload: { type },
      } = variables
      const label = (TAB_LIST.find(v => v.value === type)?.label || "") + "로"
      toast.success(`${label} 복제 되었습니다.`)
    },
  })

  const flatMap = data?.pages.flatMap(v => v.data)
  const dataSource = (flatMap || []).map(v => {
    const otherOptions = totalOptions.filter(v => v.value !== current?.value)
    return {
      id: v.id,
      key: v.id,
      url: v.url,
      urlText: <p className="line-clamp-2 max-w-[300px]">{v.url}</p>,
      type: v.type,
      preview: <Image size="768" src={v.url} className="size-20 shrink-0 object-cover" />,
      more: (
        <Select
          placeholder="선택"
          style={{ width: 130 }}
          onChange={value => {
            copyMutation.mutate({
              storeId,
              payload: {
                type: value,
                image_ids: [v.id],
              },
            })
          }}
          options={otherOptions}
        />
      ),
    }
  })

  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView && !isFetching && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, isFetching, hasNextPage, fetchNextPage])

  return (
    <div>
      <div className="h-4" />

      <div className="overflow-x-hidden">
        <ConfigProvider renderEmpty={() => <EmptyTrash label="아직 업로드한 이미지가 없어요" />}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={[
              ...dataSource,
              ...(hasNextPage
                ? [
                    {
                      key: 99999,
                      url: "",
                      more: <div ref={ref} />,
                    },
                  ]
                : []),
            ]}
            pagination={false}
            scroll={{ y: 400 }}
            className="antd-custom-scroll-x-none"
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default ImageStorageTable
