import api from "."
import { ParcelCompanyDto } from "./businessApi"
import { DjangoResponse } from "./storeApi"

type ShippingPolicyParams = {
  shippingPolicyId: number
}

type SubShippingProductPolicy = {
  shipping_fee?: number | null // 기본 배송비
  free_shipping_threshold?: number | null // 무료 배송 임계 값
  charge_per_count?: number | null // 조건 수량
}

export enum ShippingType {
  SHIPMENT = "SHIPMENT",
  DIRECT = "DIRECT",
}

export enum FeeTargetType {
  SAME_GROUP = "SAME_GROUP",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum FeeType {
  FREE = "FREE",
  ONCE = "ONCE",
  CONDITIONAL_FREE = "CONDITIONAL_FREE",
  REPEAT = "REPEAT",
}

type ShippingPolicyDto = {
  id: number
  title: string // 타이틀
  shipping_type: ShippingType // 배송 방법
  is_direct: boolean // 방문 수령 여부
  is_quick: boolean // 퀵 서비스 여부
  is_pre_paid: boolean // 배송비 청구 타입
  fee_target_type: FeeTargetType // 배송비 부과 범위 타입
  fee_type: FeeType // 기본 배송비 부과 타입
  is_extra_fee: boolean // Is extra fee
  jeju_extra_fee?: number | null // 제주 지역 추가 배송비
  etc_extra_fee?: number | null // 제주 외 지역 추가 배송비
  sub_shipping_product_policy?: SubShippingProductPolicy // 서브 배송 정책
  parcel_company: ParcelCompanyDto

  return_exchange_parcel_company: ParcelCompanyDto
  return_fee: number
  exchange_fee: number
  zip_code: string
  address: string
  detail_address: string
}

export type ShippingPolicyCreateDto = Omit<
  ShippingPolicyDto,
  "id" | "parcel_company" | "return_exchange_parcel_company"
> & {
  parcel_company: ParcelCompanyDto["code"]
  return_exchange_parcel_company: ParcelCompanyDto["code"]
}

export type ShippingPolicyUpdateDto = ShippingPolicyCreateDto

export const shippingPolicyQueryKeys = {
  all: () => ["shippingPolicy"],
  shippingPolicy: (params: ShippingPolicyParams) => [...shippingPolicyQueryKeys.all(), params],
}

const shippingPolicyApi = {
  getShippingPolicy: async ({ shippingPolicyId }: ShippingPolicyParams) => {
    const { data } = await api.get<DjangoResponse<ShippingPolicyDto>>(`/business/shipping-policy/${shippingPolicyId}`)
    return data
  },
  patchShippingPolicy: async ({
    shippingPolicyId,
    payload,
  }: {
    shippingPolicyId: number
    payload: ShippingPolicyUpdateDto
  }) => {
    const { data } = await api.patch<DjangoResponse<ShippingPolicyDto>>(
      `/business/shipping-policy/${shippingPolicyId}`,
      payload,
    )
    return data
  },

  postShippingPolicyCopy: async ({ shippingPolicyId }: { shippingPolicyId: number }) => {
    const { data } = await api.post<DjangoResponse<ShippingPolicyDto>>(
      `/business/shipping-policy/${shippingPolicyId}/copy`,
    )
    return data
  },

  deleteShippingPolicy: async ({ shippingPolicyId }: { shippingPolicyId: number }) => {
    const { data } = await api.delete(`/business/shipping-policy/${shippingPolicyId}`)
    return data
  },
}

export default shippingPolicyApi
