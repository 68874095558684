import { Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { useTableFilterContext } from "../context/table-filter-context"

const SettlementFilterCheckbox = () => {
  const { checkboxState, setCheckboxState } = useTableFilterContext()

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    setCheckboxState({
      all: checked,
      waiting: checked,
      failed: checked,
      complete: checked,
    })
  }

  const handleCheckItem = (key: string) => (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    setCheckboxState(prev => {
      const updatedState = { ...prev, [key]: checked }
      const allChecked = updatedState.waiting && updatedState.failed && updatedState.complete

      if (allChecked) {
        return {
          all: true,
          waiting: true,
          failed: true,
          complete: true,
        }
      }

      return {
        ...updatedState,
        all: false,
      }
    })
  }

  return (
    <div className="flex gap-5">
      <p className="w-[140px]">정산 상태조회</p>
      <label className="flex items-center gap-1">
        <Checkbox checked={checkboxState.all} onChange={handleCheckAll} />
        전체
      </label>
      <label className="flex items-center gap-1">
        <Checkbox checked={checkboxState.waiting} onChange={handleCheckItem("waiting")} />
        정산대기중
      </label>
      <label className="flex items-center gap-1">
        <Checkbox checked={checkboxState.failed} onChange={handleCheckItem("failed")} />
        정산실패
      </label>
      <label className="flex items-center gap-1">
        <Checkbox checked={checkboxState.complete} onChange={handleCheckItem("complete")} />
        정산완료
      </label>
    </div>
  )
}

export default SettlementFilterCheckbox
