import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DatePicker, Radio, RadioChangeEvent } from "antd"
import dayjs, { Dayjs } from "dayjs"

const radioOptions = [
  { value: "1-week", label: "1주일" },
  { value: "1-month", label: "1개월" },
  { value: "3-month", label: "3개월" },
  { value: "custom", label: "직접입력" },
]

const START_DATE_KEY = "start_date"
const END_DATE_KEY = "end_date"
const DATE_FORMAT = "YYYY-MM-DD"

const DurationFilter = () => {
  const [radioValue, setRadioValue] = useState<string>("1-week")

  const [searchParams, setSearchParams] = useSearchParams()

  const startDate = searchParams.get(START_DATE_KEY) || dayjs().subtract(1, "week").format(DATE_FORMAT)
  const endDate = searchParams.get(END_DATE_KEY) || dayjs().format(DATE_FORMAT)

  const handleStartDateChange = (date: Dayjs) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")

    // 선택일이 endDate보다 크면 swap
    if (date.isAfter(dayjs(endDate))) {
      searchParams.set(START_DATE_KEY, endDate)
      searchParams.set(END_DATE_KEY, formattedDate)
    } else {
      searchParams.set(START_DATE_KEY, formattedDate)
    }

    setSearchParams(searchParams)
  }

  const handleEndDateChange = (date: Dayjs) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")

    // 선택일이 startDate보다 작으면 swap
    if (date.isBefore(dayjs(startDate))) {
      searchParams.set(START_DATE_KEY, formattedDate)
      searchParams.set(END_DATE_KEY, startDate)
    } else {
      searchParams.set(END_DATE_KEY, formattedDate)
    }

    setSearchParams(searchParams)
  }

  const handleRadioChange = (event: RadioChangeEvent) => {
    const value = event.target.value
    const currentDate = dayjs()

    const dateRanges: Record<string, Dayjs> = {
      "1-week": currentDate.subtract(1, "week"),
      "1-month": currentDate.subtract(1, "month"),
      "3-month": currentDate.subtract(3, "month"),
      "6-month": currentDate.subtract(6, "month"),
    }

    const selectedStartDate = dateRanges[value]

    if (selectedStartDate) {
      handleStartDateChange(selectedStartDate)
      handleEndDateChange(currentDate)
    }

    setRadioValue(value)
  }

  // 라디오 조건이랑 다른경우 custom 선택
  useEffect(() => {
    if (!startDate || !endDate) return

    const currentDate = dayjs()
    const dateRanges: Record<string, Dayjs> = {
      "1-week": currentDate.subtract(1, "week"),
      "1-month": currentDate.subtract(1, "month"),
      "3-month": currentDate.subtract(3, "month"),
    }

    if (!dayjs(endDate).isSame(currentDate, "day")) {
      setRadioValue("custom")
      return
    }

    const alreadySetRadio = Object.entries(dateRanges).some(([key, date]) => {
      if (date.isSame(dayjs(startDate), "day")) {
        setRadioValue(key)
        return true
      }
    })

    if (!alreadySetRadio) {
      setRadioValue("custom")
    }
  }, [startDate, endDate])

  return (
    <div className="flex items-center gap-3">
      <span className="w-[140px] text-14 text-gray-900">기간</span>

      <DatePicker
        className="w-[140px]"
        placeholder="검색 시작일"
        value={startDate ? dayjs(startDate) : null}
        onChange={handleStartDateChange}
        allowClear={false}
      />
      <DatePicker
        className="w-[140px]"
        placeholder="검색 종료일"
        value={endDate ? dayjs(endDate) : null}
        onChange={handleEndDateChange}
        allowClear={false}
      />

      <Radio.Group options={radioOptions} value={radioValue} onChange={handleRadioChange} />
    </div>
  )
}

export default DurationFilter
