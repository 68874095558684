import React from "react"
import { toLocale } from "@runners/lib/price"
import { Button, Table } from "antd"
import dayjs from "dayjs"
import storeApi from "@/apis/storeApi"
import { ExcelIcon } from "@/assets/icons"
import Loading from "@/components/loading"
import withModalLayout from "@/hoc/with-modal-layout"
import { PaymentMethod, SettlementShippingType } from "../../constants"
import { useGetSettlementDetailQuery } from "../../hooks/use-get-query"

interface SettlementDetailModalProps {
  settlementGroupId: number
}

const columns = [
  { key: "number", title: "No.", dataIndex: "number" },
  { key: "payment_method", title: "결제수단", dataIndex: "payment_method" },
  { key: "product_title", title: "상품명", dataIndex: "product_title" },
  { key: "product_option_title", title: "상품옵션", dataIndex: "product_option_title" },
  { key: "order_number", title: "주문번호", dataIndex: "order_number" },
  { key: "ordered_at", title: "주문일자", dataIndex: "ordered_at" },
  { key: "shipping_type", title: "배송구분", dataIndex: "shipping_type" },
  { key: "orderer_name", title: "주문자", dataIndex: "orderer_name" },
  { key: "sales_price", title: "판매가", dataIndex: "sales_price" },
  { key: "supply_price", title: "공급가", dataIndex: "supply_price" },
  { key: "etc", title: "비고", dataIndex: "etc" },
]

const SettlementDetailModal = withModalLayout<SettlementDetailModalProps>(props => {
  const { close: _close, settlementGroupId } = props

  const { data = [], isLoading } = useGetSettlementDetailQuery({
    settlementGroupId,
  })

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-40">
        <Loading />
      </div>
    )
  }

  const dataSource = data.map((item, index) => ({
    key: index,
    number: index + 1,
    payment_method: PaymentMethodText({ paymentMethod: item.payment_method }),
    product_title: item.product_title,
    product_option_title: item.product_option_title,
    order_number: item.order_number,
    ordered_at: dayjs(item.ordered_at).format("YYYY년 MM월 DD일 HH:mm:ss"),
    shipping_type: ShippingTypeText({ shippingType: item.shipping_type }),
    orderer_name: item.orderer_name,
    sales_price: toLocale(item.sales_price),
    supply_price: toLocale(item.supply_price),
    etc: "",
  }))

  const onDownloadExcel = async () => {
    try {
      const response = await storeApi.postSettlementGroupExcel({ settlementGroupId })
      window.open(`${response.data.download_url}`, "_blank")
    } catch (e) {
      console.error(e)
      window.alert("다운로드에 실패했어요.")
    }
  }

  return (
    <div className="">
      <main className="p-5">
        <div className="flex items-center justify-end">
          <Button className="group" onClick={onDownloadExcel}>
            <ExcelIcon className="size-[14px] text-gray-700 transition-all duration-300 group-hover:text-primary" />
            엑셀 다운로드
          </Button>
        </div>
        <div className="h-4" />
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            position: ["bottomCenter"],
          }}
        />
      </main>
    </div>
  )
})

export default SettlementDetailModal

const PaymentMethodText = ({ paymentMethod }: { paymentMethod: PaymentMethod }) => {
  switch (paymentMethod) {
    case PaymentMethod.CARD:
      return "신용카드"
    case PaymentMethod.BANK_TRANSFER:
      return "계좌이체"
    case PaymentMethod.MOBILE:
      return "모바일"
    case PaymentMethod.VIRTUAL_ACCOUNT:
      return "가상계좌"
    case PaymentMethod.DIRECT_BANK_DEPOSIT:
      return "무통장입금"
  }
}

const ShippingTypeText = ({ shippingType }: { shippingType: SettlementShippingType }) => {
  switch (shippingType) {
    case SettlementShippingType.COMPANY:
      return "업체배송"
    default:
      return "-"
  }
}
