import { statusOptionList } from "@runners/lib/constants"
import { toLocale } from "@runners/lib/price"
import { Badge } from "@runners/ui"
import { Table } from "antd"
import { useGetLastBulkProductsQuery } from "../hooks/use-get-query"

const BulkProductsTable = () => {
  const { data } = useGetLastBulkProductsQuery()

  /**
   * antd 컬럼
   */
  const columns = [
    {
      key: "status",
      dataIndex: "status",
      title: "처리상태",
    },
    {
      key: "product_number",
      dataIndex: "product_number",
      title: "상품번호",
      width: "20%",
    },
    {
      key: "product_status",
      dataIndex: "product_status",
      title: "판매상태",
    },
    {
      key: "category_title",
      dataIndex: "category_title",
      title: "카테고리",
      width: "10%",
    },
    {
      key: "product_title",
      dataIndex: "product_title",
      title: "상품명",
      width: "30%",
    },
    {
      key: "product_discount_price",
      dataIndex: "product_discount_price",
      title: "판매가",
    },
    { key: "product_stock_count", dataIndex: "product_stock_count", title: "재고" },
  ]

  /**
   * antd 테이블 값
   */
  const dataSource = data?.product_uploads?.map(product => {
    return {
      status: product.status === "SUCCESS" ? <Badge color="blue">성공</Badge> : <Badge color="red">실패</Badge>,
      product_number: product.product_number,
      product_status: statusOptionList.find(status => status.value === product.product_status)?.label || "-",
      category_title: product.category_title,
      product_title: product.product_title,
      product_discount_price: toLocale(product.product_discount_price),
      product_stock_count: toLocale(product.product_stock_count),
    }
  })

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{
        position: ["bottomCenter"],
      }}
    />
  )
}

export default BulkProductsTable
