import { useMediaQuery } from "@runners/hooks"
import { landing1, landing2, landing3, landing4, landing5, landing6, landing7, landing8 } from "@/assets/images/guide"

const ResponsiveSpace = () => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  if (isMobile) return <br />
  return " "
}

export const GUIDE_MAP = [
  {
    step: 1,
    title: "입점 신청 버튼 클릭",
    description: (
      <>
        센스 스토어 홈페이지 우측 상단
        <ResponsiveSpace />
        <strong>‘지금 입점하기’</strong> 버튼을 클릭해주세요.
      </>
    ),
    src: landing1,
  },
  {
    step: 2,
    title: "회원가입 버튼 클릭",
    description: (
      <>
        <strong>카카오로 시작하기</strong> 또는 <strong>이메일 회원가입</strong>
        <ResponsiveSpace />
        버튼을 클릭해주세요.
      </>
    ),
    src: landing2,
  },
  {
    step: 3,
    title: "약관 동의 확인",
    description: <>서비스 회원가입에 대한 약관 동의에 체크를 해주세요.</>,
    src: landing3,
  },
  {
    step: 4,
    title: "회원 정보 입력",
    description: <>가입하실 회원 정보를 입력해 주세요.</>,
    src: landing4,
  },
  {
    step: 5,
    title: "사업자 정보 입력",
    description: <>가입하실 사업자 정보를 입력해 주세요.</>,
    src: landing5,
  },
  {
    step: 6,
    title: "상품 이미지 등록",
    description: (
      <>
        센스 관리자가 간단하게 확인할 수 있는
        <ResponsiveSpace />첫 상품을 등록해 주세요.
      </>
    ),
    src: landing6,
  },
  {
    step: 7,
    title: "가입 완료 후 스토어로 이동",
    description: <>센스 스토어의 가입을 진심으로 축하드립니다.</>,
    src: landing7,
  },

  {
    step: 8,
    title: (
      <>
        추가 정보 입력 완료 시<ResponsiveSpace />
        정식 판매자 승인
      </>
    ),
    description: (
      <>
        부가적인 사업자 정보를 입력해 주시면
        <ResponsiveSpace />
        정식 판매자로 승인 해드립니다.
      </>
    ),
    src: landing8,
  },
]
