import { Link } from "react-router-dom"
import Logo from "@/components/logo"
import IssueCollector from "./issue-collector"
import UserInfoDropDown from "./user-info-dropdown"

const Header = () => {
  return (
    <div className="flex h-[64px] items-center justify-between bg-white px-6">
      <Link to="/" className="py-3.5">
        <Logo />
      </Link>

      <div className="flex items-center gap-3">
        <IssueCollector />
        <UserInfoDropDown />
      </div>
    </div>
  )
}

export default Header
